import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { searchCircleOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import receivingOrdersController from "../../../../../../barrel/controllers/receivingOrdersController";
import usePrevious from "../../../../../../barrel/hooks/usePrevious";
import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomAppliedFilterChip from "../../../../../CustomElements/CustomAppliedFilterChip/CustomAppliedFilterChip";
import CustomArrowBack from "../../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomErrorNotice from "../../../../../CustomElements/CustomErrorNotice/CustomErrorNotice";
import CustomSearchBar from "../../../../../CustomElements/CustomSearchBar/CustomSearchBar";
import ErrorNoticeRequiresNetworkLoadObject from "../../../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import HxfInfiniteScroll from "../../../../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
import ProgressBar from "../../../../../CustomElements/ProgressBar/ProgressBar";
import InAppTemplate from "../../../../../InAppTemplate/InAppTemplate";
import styles from "./ReceivingOrdersShow.module.scss";
import DatesConversionService from "../../../../../../barrel/services/datesConversionService";
import CustomUnavailableErrorNotice from "../../../../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
import useSyncSettings from "../../../../../../barrel/hooks/useSyncSettings";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
function ReceivingOrdersShow(props) {
  const syncSettings = useSyncSettings();
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);
  const previousDidMount = usePrevious(didMount);
  const sizePage = 25;

  const loadedObjsRef = useRef(null);
  const {t} = useHxfTranslation();
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const infiniteScrollEnabledRef = useRef(false);
  const allowedInfiniteScrollLoadPageRef = useRef(0);
  const [indexReRender, setIndexReRender] = useState(0);

  const [searchbarInput, setSearchbarInput] = useState("");
  const timerStartSearching = useRef(0);

  const receivingOrdersSearchFilters = useRef({});
  const posContainerRef = useRef(null);
  const [filtersApplied, setFiltersApplied] = useState([]);
  const previousFiltersApplied = usePrevious(filtersApplied);
  function isHidden(el) {
    return el.offsetParent === null;
  }

  const initiateFilterCode = (val) => {
    setSearchbarInput(val);
    clearTimeout(timerStartSearching.current); //stops spam requests
    timerStartSearching.current = setTimeout(
      () => {
        receivingOrdersSearchFilters.current = {
          ...receivingOrdersSearchFilters.current,
          searchByCode: val,
        };
        restartSearch();
      },
      500,
      val
    );
  };

  const onFilterChipRemove = (index) => {
    let newFiltersApplied = [];
    for (let i = 0; i < filtersApplied.length; i++) {
      if (i !== index) {
        newFiltersApplied.push(filtersApplied[i]);
      }
    }
    setFiltersApplied(newFiltersApplied); //will trigger useeffect
  };

  const getProperFilterChip = (obj, index) => {
    //custom styling of chip
    if (obj.filterKey === "Id_BuyOrder") {
      return (
        <CustomAppliedFilterChip
          filterTextElement={
            <>
              <b>{t("buy.order")}:</b> {obj.orderCode}
            </>
          }
          onRemoveFilter={() => {
            onFilterChipRemove(index);
          }}
        />
      );
    }

    let labelDefault = obj?.filterLabel ? obj.filterLabel : obj.filterKey;
    let chipVal = obj[obj.filterKey];
    //default
    return (
      <CustomAppliedFilterChip
        filterTextElement={
          <>
            <b>{labelDefault}:</b> {chipVal}
          </>
        }
        onRemoveFilter={() => {
          onFilterChipRemove(index);
        }}
      />
    );
  };
  const getFormattedFiltersForRequest = (filtersArray) => {
    let filtersArr = [...filtersArray];
    let formattedFilters = {};
    for (let i = 0; i < filtersArr.length; i++) {
      let filterKey = filtersArr[i].filterKey;
      let filterValue = filtersArr[i][filterKey];
      formattedFilters[filterKey] = filterValue;
    }

    return formattedFilters;
  };

  const getBuyOrderElement = (obj, index) => {
    let buyOrderData = obj.buyOrderData;
    buyOrderData = JSON.parse(buyOrderData);
    if (!buyOrderData?.Id_BuyOrder) {
      //if has no associated order
      return <></>;
    }
    return (
      <div className={styles.orderNameCodeContainer}>
        <div className={styles.orderTopLabel}>
          <b>{t("buy.order")}</b>
        </div>
        <div className={styles.orderNameCode}>
          <div
            className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
          >
            ({buyOrderData.code})
          </div>
          <div
            className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
          >
            {buyOrderData.name}
          </div>
        </div>
      </div>
    );
  };

  const loadMore = useCallback(
    (passedFilters = null) => {
      let currentPage = allowedInfiniteScrollLoadPageRef.current;

      let extraFiltersApply = {};
      if (passedFilters) {
        extraFiltersApply = getFormattedFiltersForRequest(passedFilters);
      } else {
        extraFiltersApply = getFormattedFiltersForRequest(filtersApplied);
      }
      setIsLoadingMore(true);
      receivingOrdersController()
        .getReceivingOrders({
          page: currentPage,
          size: sizePage,
          openOnly: true,
          includeBuyOrderData: true,
          ...receivingOrdersSearchFilters.current,
          ...extraFiltersApply,
        })
        .then((resp) => {
          setIsLoadingMore(false);
          syncSettings.check(resp);
          let posData = resp.data.response.data.return;
          let posTotalSize = resp.data.response.data.totalSize;
          let posExtraData = resp.data.response.data.extra;

          if (currentPage === 0) {
            //first load only
            //loads the extraData that loads in the backend only when the page = 0
            //this is done to avoid double requests just to gather extra unrelated data
          }

          let newArrayLoadedObjs = [];

          if (loadedObjsRef.current !== null) {
            newArrayLoadedObjs = [...loadedObjsRef.current, ...posData];
          } else {
            newArrayLoadedObjs = posData;
          }

          loadedObjsRef.current = newArrayLoadedObjs;

          let hasMoreElements =
            newArrayLoadedObjs.length < posTotalSize && posData.length !== 0;
          if (hasMoreElements) {
            allowedInfiniteScrollLoadPageRef.current =
              allowedInfiniteScrollLoadPageRef.current + 1;
            infiniteScrollEnabledRef.current = true;
          } else {
            infiniteScrollEnabledRef.current = false;
          }
          if (currentPage === 0) {
            setLoadingPage(false);
          }
          //setLoadedObjs(loadedObjsRef.current);
         
          setIndexReRender(indexReRender + 1);
        })
        .catch((resp) => {
          setIsLoadingMore(false);
          console.log("THE ERROR: ", resp);
          if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
            if (failedToLoadPosError) {
              setFailedToLoadPosError(false);
            }
            setRequiresNetworkLoadError(true);
          } else {
            if (requiresNetworkLoadError) {
              setRequiresNetworkLoadError(false);
            }
            setFailedToLoadPosError(true);
          }
          console.log("FAILED::", resp);

          setLoadingPage(false);
        });
    },
    [
      syncSettings,
      failedToLoadPosError,
      indexReRender,
      requiresNetworkLoadError,
      filtersApplied,
    ]
  );
  const restartSearch = useCallback(() => {
    allowedInfiniteScrollLoadPageRef.current = 0;
    loadedObjsRef.current = null;

    loadMore();
  }, [loadMore, loadedObjsRef, allowedInfiniteScrollLoadPageRef]);
  useEffect(() => {
    if (!didMount) {
      let loadingFilters = props?.location?.state?.applyFilters;

      if (loadingFilters) {
        setFiltersApplied(loadingFilters);
      } else {
        loadingFilters = null;
      }

      window.history.replaceState({}, document.title); //clear history state

      loadMore(loadingFilters);
      setDidMount(true);
    }
  }, [
    props?.location?.state?.applyFilters,
    didMount,
    failedToLoadPosError,
    requiresNetworkLoadError,
    sessionState,
    loadMore,
  ]);

  useEffect(() => {
    if (previousDidMount === didMount && didMount) {
      if (previousFiltersApplied !== filtersApplied) {
        console.log("filters were changed");
        restartSearch();
      }
    }
  }, [
    filtersApplied,
    previousDidMount,
    didMount,
    previousFiltersApplied,
    restartSearch,
  ]);

  if (!didMount) {
    return <></>;
  }

  return (
    <InAppTemplate>
      <CustomArrowBack pushUrl="/feature/receivings" />
      <div className={styles.pageTitle}>{t('receivingorders')}</div>

      <div className={styles.settingsContainer}>
        <div className={styles.customSearchBarContainer}>
          <CustomSearchBar
            value={searchbarInput}
            onResetTextClick={() => {
              initiateFilterCode("");
            }}
            onChange={(evt) => {
              initiateFilterCode(evt.target.value);
            }}
            iconElement={
              <div className={styles.searchIconStyles}>
                <IonIcon icon={searchCircleOutline} />
              </div>
            }
            placeholder={t('receivingorder.code')}
          />
        </div>
      </div>
      {filtersApplied.length > 0 && (
        <div className={styles.appliedFiltersContainer}>
          {filtersApplied.map((obj, index) => (
            <div key={"cafc_" + index}>{getProperFilterChip(obj, index)}</div>
          ))}
        </div>
      )}

      {loadingPage ? (
        <></>
      ) : (
        <>
          <div className={styles.receivingOrdersContainer}>
            <div
              id="receivingOrdersSelectionDiv"
              key={"rerender_ref_selection_" + indexReRender}
              className={styles.receivingOrdersSelection}
            >
              {!failedToLoadPosError && loadedObjsRef.current !== null ? (
                <>
                  {loadedObjsRef?.current?.length === 0 && (
                    <div>{t('no.receiving.orders.available')}</div>
                  )}
                  {loadedObjsRef.current.map((obj, index) => (
                    <div
                      key={"ro_" + index}
                      className={styles.receivingOrderElement}
                      data-btn-receivingorder-code={obj.code}
                    >
                      <IonCardHeader
                        className={styles.elemElement}
                        onClick={() => {
                          props.history.push(
                            "/feature/receivings/receiving-orders/" + obj.Id
                          );
                        }}
                      >
                        <div className={styles.elemBackgroundContainer}>
                          <WarehouseIcon />
                        </div>
                        <div className={styles.orderNameCodeContainer}>
                          <div className={styles.orderTopLabel}>
                            <b>{t('receivingorder')}</b>
                          </div>
                          <div className={styles.orderNameCode}>
                            <div
                              className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
                            >
                              ({obj.code})
                            </div>
                            <div
                              className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
                            >
                              {obj.name}
                            </div>
                          </div>
                        </div>
                        {getBuyOrderElement(obj, index)}
                        <div className={styles.creationDateContainer}>
                          <div>
                            <b>{t('date.due')}</b>
                          </div>
                          <div>
                            {obj?.dueDate
                              ? DatesConversionService().tableFormattedDateTimeLabel(
                                  obj.dueDate
                                )
                              : t('no.limit')}
                          </div>
                        </div>
                        <div className={styles.progressBarContainer}>
                          <ProgressBar
                            pgValue={obj.receivingOrder_completePct}
                          />
                        </div>
                      </IonCardHeader>
                    </div>
                  ))}{" "}
                </>
              ) : (
                <></>
              )}

              {isLoadingMore && (
                <IonCardHeader
                  className={styles.elemElement}
                  onClick={() => {}}
                >
                  <div className={styles.elemBackgroundContainer}>
                    <WarehouseIcon />
                  </div>
                  <IonCardSubtitle>
                    <div className={styles.elemElementTitle}>Loading...</div>
                  </IonCardSubtitle>
                  <IonCardTitle className={styles.elemElementDescription}>
                    <IonSpinner />
                  </IonCardTitle>
                </IonCardHeader>
              )}

              <HxfInfiniteScroll
                containerElementRef={posContainerRef}
                requiresContainerHavingScrollbar={true}
                onLoadMore={() => {
                  /*let allowingVal = allowedInfiniteScrollLoadPageRef.current + 1;
            
              if(allowingVal > 7){
                return;
              }
              console.log("allowing more :", allowingVal);
               allowedInfiniteScrollLoadPageRef.current = allowedInfiniteScrollLoadPageRef.current + 1;
              */

                  if (infiniteScrollEnabledRef.current) {
                    
                    loadMore();
                  }
                }}
                allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
              />
            </div>
          </div>
          {failedToLoadPosError ? (
            <div className={styles.errorArea}>
              <CustomUnavailableErrorNotice />
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {requiresNetworkLoadError ? (
        <div className={styles.errorArea}>
          <ErrorNoticeRequiresNetworkLoadObject />
        </div>
      ) : (
        <></>
      )}
    </InAppTemplate>
  );
}

export default ReceivingOrdersShow;
