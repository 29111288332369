import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  useIonToast
} from "@ionic/react";
import { Chip } from "@mui/material";
import { constructOutline, hammerOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import productionController from "../../../../../../../barrel/controllers/productionController";
import productionOrdersController from "../../../../../../../barrel/controllers/productionOrdersController";
import ProductionRecordDto from "../../../../../../../barrel/dataTransferObjects/productionRecordDto";
import { useCancelToken } from "../../../../../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../../../../../barrel/hooks/useLocalStorageHandler";
import usePrevious from "../../../../../../../barrel/hooks/usePrevious";
import useProductionHook from "../../../../../../../barrel/hooks/useProductionHook";
import usePunchclock from "../../../../../../../barrel/hooks/usePunchclock";
import useSyncSettings from "../../../../../../../barrel/hooks/useSyncSettings";
import ActionManagerService from "../../../../../../../barrel/services/actionManagerService";
import DatesConversionService from "../../../../../../../barrel/services/datesConversionService";
import OperationsAccessService from "../../../../../../../barrel/services/operationsAccessService";
import ProductionService from "../../../../../../../barrel/services/productionService";
import LoadingSpinnerImg from "../../../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomErrorNotice from "../../../../../../CustomElements/CustomErrorNotice/CustomErrorNotice";
import HxfDividerWithContent from "../../../../../../CustomElements/HxfDividerWithContent/HxfDividerWithContent";
import { dispatchCustomPopupsStore } from "../../../../../../CustomElements/ProductionUI/CustomPopups/CustomPopupsStore";
import ProductionTopInfo from "../../../../../../CustomElements/ProductionUI/ProductionTopInfo/ProductionTopInfo";
import ProgressBar from "../../../../../../CustomElements/ProgressBar/ProgressBar";
import TaskProgressBar from "../../../../../../CustomElements/TaskProgressBar/TaskProgressBar";
import { dispatchProductionOrderSelectionStore, useProductionOrderSelectionStore } from "../../../../../ProductionOrders/Standard/store/productionOrderSelectionStore";
import { dispatchPreActionsStore, usePreActionsStore } from "../../preActionsStore";
import PreActionSelectMachine from "../PreActionViews/SelectMachine/PreActionSelectMachine";
import styles from "./ProductionStartStage.module.scss";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import UnavailabilitiesService from "../../../../../../../barrel/services/unavailabilitiesService";
import SimpleContentPopup from "../../../../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import PreActionNewOrResumeOperation from "../PreActionViews/NewOrResumeOperation/PreActionNewOrResumeOperation";
import OnGoingProductionsPopup from "../../../../../../CustomElements/ProductionUI/CustomPopups/OnGoingProductionsPopup/OnGoingProductionsPopup";
import RvHookupIcon from '@mui/icons-material/RvHookup';
import PreActionSelectPopSubcontract from "../PreActionViews/SelectPopSubcontract/PreActionSelectPopSubcontract";
import OperationExternalTip from "./Subcomponents/OperationExternalTip";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
interface IMapIrregularityRecoveryFlowObject {
  recoveryBase_Id_OperationProduct: number;
  code: string;
  name: string;
  orderedOperations: [];
}
interface IMapIrregularityRecoveryFlow {
  [idBaseOperation: number]: IMapIrregularityRecoveryFlowObject;
}

function ProductionStartStage(props: any) {
  const {t} = useHxfTranslation();
  const syncSettings = useSyncSettings();
  const { cancelToken, isCancel } = useCancelToken({
    alias: "ProductionStartStage",
  });
  const punchclock = usePunchclock();
  const [present, dismiss] = useIonToast();
  const productionHook = useProductionHook();
  const localStorage = useLocalStorageHandler();
  const productionOrderId = props.match.params.poId;
  const productionOrderProductId = parseInt(props.match.params.popId);

  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const [isOperationLoading, setIsOperationLoading] = useState(false);
  const [isSubOperationLoading, setIsSubOperationLoading] = useState(false);
  const [isPopCompleted, setIsPopCompleted] = useState(false);
  const [popCompletedPct, setPopCompletedPct] = useState(0);
  const [rerenderPreActionSelectMachine, setRerenderPreActionSelectMachine] = useState(0);
  const [buttonStartEnabled, setButtonStartEnabled] = useState(true);
  const [didMount, setDidMount] = useState(false);
  const { sessionState } = useGlobalState();
  const feedbackService = useFeedbackService();
  const preActionsStore = usePreActionsStore();
  const [showOnGoingPopup, setShowOnGoingPopup] = useState<any>(null);
 
  const [orderedOperationsData, setOrderedOperationsData] = useState([]); //for the normal production flow
  const [subcontractsSuppliersData,setSubcontractsSuppliersData] = useState([]);
  const [mapOperationsPendingExecutionQty, setMapOperationsPendingExecutionQty] = useState<any>({});
  const [mapOperationsStatusIrregularities, setMapOperationsStatusIrregularities] = useState<any>({});

  const preActionsData = useRef<any>(null);
  const [showPopupAskNewOrContinueOperation, setShowPopupAskNewOrContinueOperation] = useState<any>(null);
  const previousTriggeredFinishedPreactions = usePrevious(preActionsStore.triggerFinishedPreActions);
  const [
    mapRecoveryFlowOrderedOperationsData,
    setMapRecoveryFlowOrderedOperationsData,
  ] = useState<IMapIrregularityRecoveryFlow>({}); //for each "Irregularity" recovery flow of it exists

  const [showPopNotFound, setShowPopNotFound] = useState(false);

  const [productionOrderData, setProductionOrderData] = useState<any>(null);
  const [productionOrder_productData, setProductionOrder_productData] =
    useState(
      props?.dataPassed?.productionOrder_product !== undefined
        ? props?.dataPassed?.productionOrder_product
        : null
    );

    console.log("CHECK:::,", productionOrder_productData);
  const [
    minimizedOperationProductionsData,
    setMinimizedOperationProductionsData,
  ] = useState<any>([]);

  const [
    showLoadProductionOrderProductDataError,
    setShowLoadProductionOrderProductDataError,
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const handleJsonParses = (objwithUnparsedParams: any) => {
    if (objwithUnparsedParams.parameterizedVariables != null) {
      objwithUnparsedParams.parameterizedVariables = JSON.parse(
        objwithUnparsedParams.parameterizedVariables
      );
    } else {
      objwithUnparsedParams.parameterizedVariables = [];
    }
  
    if (objwithUnparsedParams.arrayProductFixedVariables != null) {
      objwithUnparsedParams.productFixedVariables =  objwithUnparsedParams.arrayProductFixedVariables;
    } else {
      objwithUnparsedParams.productFixedVariables = [];
    }
    if (
      objwithUnparsedParams.productionOrder_productParameterizedVariables !=
      null
    ) {
      objwithUnparsedParams.productionOrder_productParameterizedVariables =
        JSON.parse(
          objwithUnparsedParams.productionOrder_productParameterizedVariables
        );
    } else {
      objwithUnparsedParams.productionOrder_productParameterizedVariables = [];
    }

    return objwithUnparsedParams;
  };


  const getParameterizedVariablesInfo = () =>{
   
    let parameterizedVariables = productionOrder_productData?.parameterizedVariables;
    let productParameterizedVariables = productionOrder_productData?.productParameterizedVariables;
    if(typeof(productParameterizedVariables === 'string') || productParameterizedVariables instanceof String){
      productParameterizedVariables = JSON.parse(productParameterizedVariables);
    }

    let productionOrder_productParameterizedVariables = productionOrder_productData?.productionOrder_productParameterizedVariables;

    if(!productionOrder_productParameterizedVariables || !parameterizedVariables || !productParameterizedVariables){
      return (<></>)
    }
    let pvvs = [];

    for(let i = 0; i< productionOrder_productParameterizedVariables.length; i++){
        let idParameterizedVariable = null;
        let name = "";
        let code = "";
        
        for(let j = 0; j<productParameterizedVariables.length; j++){
          if(productParameterizedVariables[j].Id === productionOrder_productParameterizedVariables[i].Id_Product_ParameterizedVariable){
            idParameterizedVariable = productParameterizedVariables[j].Id_ParameterizedVariable;
            break;
          }
        }

        if(idParameterizedVariable){
          for(let j = 0; j<parameterizedVariables.length; j++){
            if(parameterizedVariables[j].Id === idParameterizedVariable){
              pvvs.push({
                name: parameterizedVariables[j].name,
                code: parameterizedVariables[j].code,
                Value:productionOrder_productParameterizedVariables[i].Value
              });
              break;
            }
          }
        }

    }


    return (
      <div className={styles.parameterizedVariablesContainer}>
      {pvvs.map(
        (obj: any, index: any) => (
          <div
            key={index + "_ppv"}
            className={styles.productPvInformation}
          >
            <div className={styles.fixedVariableName}>{obj.name}:</div>
            <div className={styles.fixedVarValue}>{obj.Value}</div>
          </div>
        )
      )}
    </div>
    )
  }

  const getSubcontracts = (poData:any) => {
      let productionOrderProducts = poData.productionOrderProducts;

      let subcontracts = [];
      //len error check
      for (let i = 0; i < productionOrderProducts.length; i++) {
        if (productionOrderProducts[i].Id == productionOrderProductId) {
      
          if(productionOrderProducts[i]?.subcontracts){
            subcontracts = productionOrderProducts[i].subcontracts;
          }
          break;
        }
      }

      return subcontracts;
  };
  
 
  const handleProductionOrderProductLoad = useCallback(
    (poData) => {
      let productionOrderProducts = poData.productionOrderProducts;
      let foundPop = null;

      let subcontracts = [];
      //len error check
      for (let i = 0; i < productionOrderProducts.length; i++) {
        if (productionOrderProducts[i].Id == productionOrderProductId) {
          foundPop = productionOrderProducts[i];
          if(productionOrderProducts[i]?.subcontracts){
            subcontracts = productionOrderProducts[i].subcontracts;
          }
        }
      }

  
      
      if (foundPop == null) {
        //error handle show
        setShowLoadProductionOrderProductDataError(true);
      } else {
        foundPop = handleJsonParses(foundPop);
        setProductionOrder_productData(foundPop);
      }

      return foundPop;
    },
    [productionOrderProductId]
  );
  

  const getOrderedOperationsFlowStartingFromOperation = useCallback((
    idOperationBase: any,
    currentProduct: any,
    teamAccessibleOperationProducts: any,
    operationsAccessData:any,
    subcontracts:any
  ) => {

    let arrayOrderedOperations: any = [];
    //preparing operations ordered by the relation

    let operationIdNextSearch = 0;
    // add operation base
    for (let i = 0; i < currentProduct.operationsData.length; i++) {
      if (currentProduct.operationsData[i].Id === idOperationBase) {
        arrayOrderedOperations.push(currentProduct.operationsData[i]);
        for (
          let j = 0;
          j < currentProduct.operationsProductRelationsData.length;
          j++
        ) {
          if (
            currentProduct.operationsProductRelationsData[j][
              "Id_OperationProduct"
            ] === currentProduct.operationsData[i]["Id"]
          ) {
            operationIdNextSearch =
              currentProduct.operationsProductRelationsData[j][
                "Id_Next_OperationProduct"
              ];
            break;
          }
        }
        break;
      }
    }

    let tempMapAddedOperationIds: any = {}; //just to optimize search
    //tempMapAddedOperationIds[idOperationBase] = true;
    let isStillSearching = true;

    if (operationIdNextSearch !== 0) {
      while (isStillSearching) {

        for (let i = 0; i < currentProduct.operationsData.length; i++) {
          let idOperation = currentProduct.operationsData[i]["Id"];
          if (tempMapAddedOperationIds[idOperation]) {
            continue;
          }
          let foundOperationTeamAccessible = false;
     
          if (teamAccessibleOperationProducts !== "ALL") {
            
            for (let j = 0; j < teamAccessibleOperationProducts.length; j++) {
              let allowedOperationId = teamAccessibleOperationProducts[j].Id;
              if (allowedOperationId === idOperation) {
                foundOperationTeamAccessible = true;
                break;
              }
            }
            if (!foundOperationTeamAccessible) {
              currentProduct.operationsData[i]["teamAccessible"] = false;
            }
          }
          
          if(!foundOperationTeamAccessible){
         
            let hasAccess = OperationsAccessService().checkIfOperationsAccessArrayIncludesOperation(operationsAccessData,idOperation);
            currentProduct.operationsData[i]._shouldHide = !hasAccess;
          }
          let shouldIncludeDailyDistributionData = sessionState?.userSessionData?.operationsDistribution === "DAILY_DISTRIBUTION";
 
    
          let tasksMustBeDone = !shouldIncludeDailyDistributionData ? currentProduct.operationsData[i].UI_WS_productionOrderProductTotalOperationExecutionQuantity : currentProduct.operationsData[i].tempToday_productionOrderProductTotalOperationExecutionQuantity;
          if(tasksMustBeDone === 0){
            currentProduct.operationsData[i]._shouldHide = true;
          }

          if (idOperation === operationIdNextSearch) {


            arrayOrderedOperations.push(currentProduct.operationsData[i]);
            tempMapAddedOperationIds[currentProduct.operationsData[i]["Id"]] =
              true;
            for (
              let j = 0;
              j < currentProduct.operationsProductRelationsData.length;
              j++
            ) {
              if (
                currentProduct.operationsProductRelationsData[j][
                  "Id_OperationProduct"
                ] === idOperation
              ) {
                operationIdNextSearch =
                  currentProduct.operationsProductRelationsData[j][
                    "Id_Next_OperationProduct"
                  ];
                break;
              }
            }
            let noNextRelationFound = operationIdNextSearch === idOperation;
            if (noNextRelationFound) {
              isStillSearching = false;
              break;
            }
          }
        }
      }
    }

    if(subcontracts.length > 0){
      let arrayOrderedOperationsModded = [];
     
      for (let i = 0; i < arrayOrderedOperations.length; i++) {
        let operation = arrayOrderedOperations[i];
        arrayOrderedOperationsModded.push(arrayOrderedOperations[i]);
        if(operation?.allowExternalRecords === 1){
          let operationSubcontracts = [];
          //check for subcontracts
          let newOperationSubcontractTotalExpectedQty = 0; //sum total qty
          let newTotalOperationSubcontractDone = 0;
          for(let j = 0; j<subcontracts.length; j++){
            if(parseInt(subcontracts[j]?.Id_OperationProduct) === operation.Id){
              newOperationSubcontractTotalExpectedQty += parseFloat(subcontracts[j].Quantity);
              let productionRecords = subcontracts[j]?.productionRecords;
              if(productionRecords){
                for(let k = 0; k<productionRecords.length; k++){
                  newTotalOperationSubcontractDone += productionRecords[k].scaleProductivity;
                }
              }
              operationSubcontracts.push(subcontracts[j]);
            }
          }

          if(newOperationSubcontractTotalExpectedQty > 0){
            //has subcontract qty to do positive
            let moddedOperation = JSON.parse(JSON.stringify(operation));
            moddedOperation._isExternalOperation = true;
            moddedOperation._subcontracts = operationSubcontracts;
            moddedOperation.UI_WS_productionOrderProductTotalOperationExecutionQuantity = newOperationSubcontractTotalExpectedQty;
            moddedOperation.UI_WS_productionOrderProductTimesExecutedOperation = newTotalOperationSubcontractDone;
            arrayOrderedOperationsModded.push(moddedOperation);
          }
        }
        
      }
      arrayOrderedOperations = arrayOrderedOperationsModded;
    }
    return arrayOrderedOperations;
  },[]);

  useEffect(() => {
    if (!didMount) {
      dispatchPreActionsStore({
        type: "RESET_STORE",
      });
      let currentWorkerCode = localStorage.getWorkerIdentificationCode();
      //if didnt load from state

      let shouldIncludeDailyDistributionData = sessionState?.userSessionData?.operationsDistribution === "DAILY_DISTRIBUTION";
 
      if (productionOrderData == null) {
          productionOrdersController().getProductionOrderById(
            productionOrderId,
            {
              includeDailyDistributionData:shouldIncludeDailyDistributionData,
              includeOperationsAccessData:true,
              includePopDataByPopId: productionOrderProductId,
              workerCode: currentWorkerCode,
              getWorkerActiveOnGoingProductionsByPop: true,
              includeProductBom:true, // required for shurtcut quick maintenance
              Id_ProductionOrder_Product: productionOrderProductId,
              includeSubcontractsSuppliersData:sessionState?.userSessionData?.globalSettings?.featureExpander?.operationProductsSubcontracting === 1
            },
            cancelToken
          ).then((resp: any) => {
            syncSettings.check(resp);
            console.log("load from requested content", resp);
            let poData = resp?.data?.response?.data;
         
            if (!poData) {
              throw "unexpected poData";
            }
          
            let enabled = poData.enabled;
            let operationsAccessData =resp?.data?.response?.data?.extra?.operationsAccessData;
            let recMapOperationsPendingExecutionQty = resp?.data?.response?.data?.extra?.mapOperationsPendingExecutionQty;
            let recMapOperationStatusIrregularities = resp?.data?.response?.data?.extra?.mapOperationsStatusIrregularities;
          
            setMapOperationsPendingExecutionQty(recMapOperationsPendingExecutionQty);
            setMapOperationsStatusIrregularities(recMapOperationStatusIrregularities);
            if(enabled === 0){
              setShowPopNotFound(true);
              setIsLoading(false);
              return;
            }
            let requirePunchclockIn =
              resp?.data?.response?.data?.extra?.requirePunchclockIn;
            if (requirePunchclockIn) {
              punchclock.redirRequirePunchclockIn();
              return;
            }
            let workerOnGoingProductionRecords =
              resp.data.response.data?.extra?.getAllWorkerOnGoingPopProductions;
            workerOnGoingProductionRecords = workerOnGoingProductionRecords;
              /*ProductionRecordDto().listProductionRecordsToActiveProductionRecords(
                workerOnGoingProductionRecords
              );*/
    
            let dispatchData: any = {
              type: "SHOW_ON_GOING_PRODUCTIONS_POPUP",
              payload: {
                onGoingProductionsPopupData: workerOnGoingProductionRecords,
                onGoingProductionsPopupSettings:{
                  filters:{
                    Id_ProductionOrder_Product:productionOrderProductId
                  }
                }

               /*onGoingProductionsPopupSettings: {
                  onCloseGoToPath:
                    "/feature/production-order/" +
                    productionOrderId +
                    "/po-product/" +
                    productionOrderProductId,
                },*/
              },
            };
            setMinimizedOperationProductionsData(dispatchData);
            setProductionOrderData(poData);
            //get product data
            let currentProduct = handleProductionOrderProductLoad(poData);

            dispatchProductionOrderSelectionStore({
              type: "SET_STORE",
              payload: { productionOrder: poData, product: currentProduct },
            });

            let teamAccessibleOperationProducts =
              poData?.extra?.workerTeamAcessibleProductOperations;
            if(!teamAccessibleOperationProducts){
              teamAccessibleOperationProducts = [];
            }
            let popsCompletePct = poData.productionOrder_product_completePct;
            for (let i = 0; i < popsCompletePct.length; i++) {
              if (
                popsCompletePct[i].Id_ProductionOrder_Product ===
                productionOrderProductId
              ) {
                let popCompletePct = popsCompletePct[i].completePct;

                if (popCompletePct >= 100) {
                  setIsPopCompleted(true);
                }
                setPopCompletedPct(popCompletePct);
                break;
              }
            }

            //preparing operations ordered by the relation

            if (!currentProduct?.productData) {
              console.log(
                "unexpected error, invalid idOperationError (3829hd92)"
              );
              throw new Error(
                "unexpected error, invalid idOperationError (3829hd92)"
              );
            }
            let idOperationBase = currentProduct?.productData?.Id_OperationProduct_Base;

            let subcontracts = getSubcontracts(poData);

            let subcontractsSuppliersData = poData?.subcontractsSuppliersData;

            if(subcontractsSuppliersData){
              setSubcontractsSuppliersData(subcontractsSuppliersData);
            }
            //gets all operations from the production flow
            let arrayOrderedOperations: any =
              getOrderedOperationsFlowStartingFromOperation(
                idOperationBase,
                currentProduct,
                teamAccessibleOperationProducts,
                operationsAccessData,
                subcontracts
              );

            let mapRecoveryOperationsFlow = {} as IMapIrregularityRecoveryFlow;
            //gets all operations from recovery operations
               
            if (currentProduct?.operationsData) {
              for (let i = 0; i < currentProduct.operationsData.length; i++) {
                let operationProduct = currentProduct.operationsData[i];

                let isBaseRecovery =
                  operationProduct?.temp_isARecoveryBaseOperation;
                if (isBaseRecovery) {
                  let arrayRecoveryFlowOrderedOperations =
                    getOrderedOperationsFlowStartingFromOperation(
                      operationProduct["Id"],
                      currentProduct,
                      teamAccessibleOperationProducts,
                      operationsAccessData,
                      []
                    );

                  let newRecoveryObject =
                    {} as IMapIrregularityRecoveryFlowObject;
                  newRecoveryObject.orderedOperations =
                    arrayRecoveryFlowOrderedOperations;
                  newRecoveryObject.recoveryBase_Id_OperationProduct =
                    operationProduct["Id"];
                  newRecoveryObject.code = operationProduct.code;
                  newRecoveryObject.name = operationProduct.name;

                  mapRecoveryOperationsFlow[operationProduct["Id"]] =
                    newRecoveryObject;
                }
              }
            }

            setMapRecoveryFlowOrderedOperationsData(mapRecoveryOperationsFlow);

            setOrderedOperationsData(arrayOrderedOperations);
          })
          .catch((res) => {
            if (isCancel(res)) {
              return;
            }

            if (res?.response?.status === 404) {
              setShowPopNotFound(true);
            }
            console.log("Error occurred loading..");
            //todo error handling
            setIsLoading(false);
            setShowLoadProductionOrderProductDataError(true);
          });
      } else {
        let currentProduct =
          handleProductionOrderProductLoad(productionOrderData);
        //loaded from state
        console.log("Load from passed state ", productionOrderData);

        dispatchProductionOrderSelectionStore({
          type: "SET_STORE",
          payload: {
            productionOrder: productionOrderData,
            product: currentProduct,
          },
        });
      }
      setDidMount(true);
    }
  }, [
    punchclock,
    didMount,
    productionOrderData,
    productionOrderId,
    sessionState?.userSessionData?.operationsDistribution,
    productionOrderProductId,
    handleProductionOrderProductLoad,
    getOrderedOperationsFlowStartingFromOperation,
    localStorage,
    cancelToken,
    isCancel,
    syncSettings,
  ]);





  useEffect(() => {
    if (Array.isArray(productionOrder_productData?.productFixedVariables)) {
      setIsLoading(false);
    }
  }, [productionOrder_productData]);

  if (showPopNotFound) {
    return (
      <>
        <CustomArrowBack
          pushUrl={"/feature/production-order/" + productionOrderId}
        />
        <CustomErrorNotice
          title="Failed to load Product"
          description="We failed to load the production order product data. Please go back and try again"
          secondDescription="Please contact us if the error persists."
        />
      </>
    );
  }

  if (showLoadProductionOrderProductDataError) {
    return (
      <>
        <CustomArrowBack
          pushUrl={"/feature/production-order/" + productionOrderId}
        />
        <CustomErrorNotice
          title="Failed to load Production Order Product"
          description="We failed to load the production order product data. Please go back and try again"
          secondDescription="Please contact us if the error persists."
        />
      </>
    );
  }

  if (
    isLoading ||
    productionOrderData == null ||
    productionOrder_productData == null
  ) {
    return (
      <>
        <CustomArrowBack
          pushUrl={"/feature/production-order/" + productionOrderId}
        />
        <LoadingSpinnerImg />
      </>
    );
  }

  const handlePrStartErrors = (res:any) => {

    if (isCancel(res)) {
      return;
    }
    

    let errorsMap = res?.response?.data?.response?.issues?.errorsMap;
    let issues = res?.response?.data?.response?.issues;
    let checkUnav = UnavailabilitiesService().checkPreventStartError(errorsMap);
    if(checkUnav === "UNAV_CONTACT_SUPERVISOR"){
          feedbackService.notifyToast(t('unav.downtime.contact.supervisor'),"error");
          return;
    }
    if(issues?.errorsMap?.MACHINE_IS_BUSY){
        setRerenderPreActionSelectMachine(rerenderPreActionSelectMachine + 1);
        feedbackService.notifyToast(t('selected.machine.is.busy'),"error");
        return;
    }
    
    if(issues?.errors?.includes("error.productionOrderClosed")){
      feedbackService.notifyToast(t('error.currentProductionOrderHasBeenClosed'),"error");
      props.history.push(
        {
          pathname:"/feature/production-orders"
        }
      );
      return;
    }

    console.log(" THE CATCH RES", res);
    if (res["error"] && res["error"]["error_type"]) {
      if (res["error"]["error_type"] == "online_response_error") {
        present(
          "Failed to connect. Try again and contact us if the error persists. (k37dg739u)",
          3000
        );
        setButtonStartEnabled(true);
      }

      if (res["error"]["error_type"] == "offline_fail") {
        console.log("not found in the offline db, show some sort of error");
      }
    }
  }

  const startProductionButton = (obj:any,cancelToken?:any) => {
    setButtonStartEnabled(false);
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();
    let popId = productionOrderProductId;
    

    let idOperation = obj.Id;

    let optionsOperationStart:any = {};

    if(obj?._new_collabParentId_ProductionRecord){
      optionsOperationStart._new_collabParentId_ProductionRecord = obj._new_collabParentId_ProductionRecord;
    }

    let isCreatingCollabParentPr = false;

    if(obj?.allowOperationPrCollab === 1){
      if(!obj?._new_collabParentId_ProductionRecord){
        isCreatingCollabParentPr = true;
      }
    }

    productionHook
      .selectOperationStart(
        optionsOperationStart,
        currentWorkerCode,
        popId,
        idOperation,
        null,
        preActionsData.current,
        cancelToken
      )
      .then((res) => {
        setIsOperationLoading(false);
        let responseData = res?.data?.response?.data;
        let createdProductionRecordId = responseData?.Id;
        if(!createdProductionRecordId){
          throw "unexpected pr creation";
        }
        console.log("production started successfully", res);

        if(isCreatingCollabParentPr){

          optionsOperationStart["_new_collabParentId_ProductionRecord"] = createdProductionRecordId;
          setIsSubOperationLoading(true);
          
          
          productionHook
          .selectOperationStart(
            optionsOperationStart,
            currentWorkerCode,
            popId,
            idOperation,
            null,
            preActionsData.current,
            cancelToken
          )
          .then((res) => {
            setIsSubOperationLoading(false);
            let responseData = res?.data?.response?.data;
            let createdProductionRecordId = responseData?.Id;
            if(!createdProductionRecordId){
              throw "unexpected pr creation";
            }

            props.history.push(
              "/feature/production-order/" +
                productionOrderId +
                "/po-product/" +
                popId +
                "/production/" +
                createdProductionRecordId
            );
          }).catch((res) => {
            setIsSubOperationLoading(false);
            handlePrStartErrors(res);
          });
        }else{
          props.history.push(
            "/feature/production-order/" +
              productionOrderId +
              "/po-product/" +
              popId +
              "/production/" +
              createdProductionRecordId
          );
        }

      })
      .catch((res) => {
        setIsOperationLoading(false);
        handlePrStartErrors(res);
      });
      console.log("INIT prod?: ", currentWorkerCode);
  };

  const shouldBeGrayedOut = (obj: any, index: any) => {
    let candidatePreviousProductionRecords =
      obj.candidatePreviousProductionRecords;
    let flagRequireAskPrevious =
      obj.shopfloorSetting_askForAssociatedPreviousProductionRecord;
    let flagAutoCalculateLatestBackend =
      obj.shopfloorSetting_autoCalculatePreviousProductionRecord;
    let flagAllowNonePrevious =
      obj.shopfloorSetting_allowAssociateWithNonePreviousProductionRecord;

    let inTeamBlockNonAccessibleOperations = productionOrderSelectionStore?.productionOrder?.extra?.inTeamBlockNonAccessibleOperations === 1;

    if (inTeamBlockNonAccessibleOperations && obj?.teamAccessible === false) {
      //worker doesnt have team access to this operation
      return true;
    }
    if (index === 0) {
      return false;
    }
    if (
      (flagRequireAskPrevious === 0 && flagAutoCalculateLatestBackend === 0) ||
      flagAllowNonePrevious === 1
    ) {
      return false;
    }

    if (candidatePreviousProductionRecords.length === 0) {
      return true;
    }

    return false;
  };

  const resetPreActionsData = () => {
    preActionsData.current = null;
    dispatchPreActionsStore({
      type: "RESET_STORE",
    });
  }
  const calculatePctOperationCompleteness = (obj: any) => {
    let total = obj?.UI_WS_productionOrderProductTotalOperationExecutionQuantity;
    let val = obj?.UI_WS_productionOrderProductTimesExecutedOperation;

    if (!total || !val) {
      return "25%";
    }

    let calcTotal = (val / total) * 100;
    if (calcTotal < 25) {
      return "25%";
    }

    if (calcTotal > 100) {
      return "100%";
    }

    return "" + calcTotal + "%";
  };

  const getMinimizedOperations = (arrayOrderedOperations: any) => {
    let allOnGoingOperations = minimizedOperationProductionsData?.payload?.onGoingProductionsPopupData;

    let filteredOnGoingOperations = [];

   
    if (allOnGoingOperations && allOnGoingOperations.length > 0) {
      for (let i = 0; i < allOnGoingOperations.length; i++) {
        let isExternal = allOnGoingOperations[i]?.Id_PopSubcontract ? true : false;
        for (let j = 0; j < arrayOrderedOperations.length; j++) {
          if (
            parseInt(allOnGoingOperations[i]["Id_OperationProduct"]) ===
            arrayOrderedOperations[j].Id
          ) {
            
            if(isExternal){
              if(!arrayOrderedOperations[j]?._isExternalOperation){ //must be external
                continue; //skip didnt match the external record
              }
            }
            if(!isExternal){
              if(arrayOrderedOperations[j]?._isExternalOperation){ //must not be external
                continue; //skip didnt match the internal record
              }
            }
            filteredOnGoingOperations.push(allOnGoingOperations[i]);
          }
        }
      }
    }
    

    if (filteredOnGoingOperations.length === 0) {
      return <></>;
    }

    let filteredMinimizedOperationsData = JSON.parse(
      JSON.stringify(minimizedOperationProductionsData)
    );
    filteredMinimizedOperationsData.payload.onGoingProductionsPopupData =
      filteredOnGoingOperations;

    let idPop = productionOrderSelectionStore?.product?.Id;
    
    return (
      <>
        {allOnGoingOperations?.length > 0 && (
          <IonCardHeader
            className={`${styles.elemElementOperation} ${styles.pendingActiveProductions}`}
            onClick={() => {
              /*
              let dispatchData = filteredMinimizedOperationsData;
              dispatchCustomPopupsStore(dispatchData);*/

              setShowOnGoingPopup({showAll:true,filters:{
                Id_ProductionOrder_Product:idPop
              }});
            }}
          >
            <div className={styles.elemBackgroundContainer}>
            <IonIcon className={styles.iconElement} slot="icon-only" size="large" icon={constructOutline} />
            </div>
            <IonCardSubtitle>
              <div className={styles.elemElementTitleMinimized}>
                {filteredOnGoingOperations.length} {t('minimized.operations')}
              </div>
            </IonCardSubtitle>
            <IonCardTitle>{t("my.minimized.operations")}</IonCardTitle>
          </IonCardHeader>
        )}
      </>
    );
  };

  const handleCreateProductionCatch = (res:any) => {
 
    if (isCancel(res)) {
      return;
    }
   
    let errorsMap = res?.response?.data?.response?.issues?.errorsMap;

    let checkUnav = UnavailabilitiesService().checkPreventStartError(errorsMap);
    if(checkUnav === "UNAV_CONTACT_SUPERVISOR"){
          feedbackService.notifyToast(t('unav.downtime.contact.supervisor'),"error");
          return;
    }
    if(errorsMap?.MACHINE_IS_BUSY){
        setRerenderPreActionSelectMachine(rerenderPreActionSelectMachine + 1);
        feedbackService.notifyToast(t('selected.machine.is.busy'),"error");
        return;
    }
    

    present("An error occurred. Please try again later", 3000);
  }

  const operationSelect = (obj:any, index:any) => {

    let idPopSubcontract = null;

    if(obj?._isExternalOperation && !preActionsData?.current?.selectedId_PopSubcontract){
      console.log("SHOW ASK pop subcontract ");
      dispatchPreActionsStore({
        type: "SET_PENDING_PREACT",
        payload: {
           pendingPreActionsOperation:{
            obj:obj, index:index
           },
           pendingAction:"PREACT_SELECT_SUBCONTRACT"
        },
      });
      return;
    }
    if(obj?._isExternalOperation){
      idPopSubcontract = preActionsData?.current?.selectedId_PopSubcontract;
      if(!idPopSubcontract){
        throw "unexpected error, should have retrieved an popsubcontact id";
        
      }
    }

   

    if (shouldBeGrayedOut(obj, index)) {
      return;
    }

    let isFirstOperation = obj.Id === productionOrderSelectionStore?.product?.Id_OperationProduct_Base;
    let enableAssociatePopupOnThisOperation = false;
    if (index !== 0) {
      enableAssociatePopupOnThisOperation = true;
    } else if (obj?.executionNumberOfTimes > 1) {
      enableAssociatePopupOnThisOperation = true;
    }
    let popId = productionOrderProductId;
    let idOperation = obj.Id;
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();

    let newProductionOptions:any = {};


    let isCreatingCollabParentPr = false;
    if(obj?._new_collabParentId_ProductionRecord){
      newProductionOptions = {
        _new_collabParentId_ProductionRecord:obj._new_collabParentId_ProductionRecord
      };
    }

    if(obj?.allowOperationPrCollab === 1){
      if(!obj?._new_collabParentId_ProductionRecord){
        isCreatingCollabParentPr = true;
      }
    }
    

    let isCreatingCollabChildPr = obj?._new_collabParentId_ProductionRecord ? true : false;

    let ignorePreactions = isCreatingCollabChildPr === true; //when it is a child we dont want to run "preactions" again, since it was already run in the "parent record"

    if (!ignorePreactions && obj?.shopfloorSetting_selectMachine === 1 && !preActionsData?.current?.selectedMachine) {

      
      dispatchPreActionsStore({
        type: "SET_PENDING_PREACT",
        payload: {
           pendingPreActionsOperation:{
            obj:obj, index:index
           },
           pendingAction:"PREACT_SELECT_MACHINE"
        },
      });

    }else if (
      enableAssociatePopupOnThisOperation &&
      (obj?.shopfloorSetting_askForAssociatedPreviousProductionRecord ===
        1 ||
        obj?.shopfloorSetting_autoCalculatePreviousProductionRecord === 1)
    ) {
      //check if has flag
      setIsOperationLoading(true);
      let operationAskPreviousProductionSettings =
        ProductionService().loadOperationAskPreviousProductionSettings(
          obj,
          isFirstOperation
        );

      //show but loading
      let dispatchData = {
        type: "SHOW_ASK_PREV_PRODUCTIONRECORD_POPUP",
        payload: {},
      };
      dispatchCustomPopupsStore(dispatchData);

      //

      productionOrdersController()
        .getCandidatesToPop(currentWorkerCode, popId)
        .then((res) => {
          setIsOperationLoading(false);
          console.log("[XYZ]done ,", res);
          let updatedOperations = res?.data?.response?.data;

          if (!updatedOperations) {
            present("Failed to load previous operations (error2)", 3000);
          } else {
            let found = false;
            let candidates = [];
            for (let i = 0; i < updatedOperations.length; i++) {
              if (updatedOperations[i].Id === idOperation) {
                candidates =
                  updatedOperations[i].candidatePreviousProductionRecords;
                found = true;
                break;
              }
            }

            if (!found) {
              present(
                "Failed to load previous operations (error3)",
                3000
              );
            } else {
              let candidatePreviousProductionRecordsToAssociate =
                candidates;
              let dispatchData = {
                type: "SHOW_ASK_PREV_PRODUCTIONRECORD_POPUP",
                payload: {
                  associatePreviousProductionRecordPopupSettings: {
                    ...operationAskPreviousProductionSettings,
                  },
                  associatePreviousProductionRecordPopupData: {
                    candidatePreviousProductionRecordsToAssociate:
                      candidatePreviousProductionRecordsToAssociate,
                    popId: popId,
                    idOperation: idOperation,
                    productionOrderId: productionOrderId,
                    preActionsData: preActionsData.current,
                  },
                },
              };

              dispatchCustomPopupsStore(dispatchData);
            }
          }
        })
        .catch(() => {
          setIsOperationLoading(false);
          dispatchCustomPopupsStore({
            type: "CLOSE_ASK_PREV_PRODUCTIONRECORD_POPUP",
          });
          present("Failed to load previous operations", 3000);
        });

    } else {
      setIsOperationLoading(true);

      if (isFirstOperation && !isCreatingCollabChildPr) {
        startProductionButton(obj, cancelToken);
      } else {
        ActionManagerService().resetAllScreensState();
        productionController()
          .createProduction(
            newProductionOptions,
            currentWorkerCode,
            null,
            idOperation,
            "none",
            popId,
            null,
            preActionsData.current,
            cancelToken
          )
          .then((res: any) => {
            setIsOperationLoading(false);
            let responseData = res?.data?.response?.data;
            let createdProductionRecordId = responseData?.Id;
            if(!createdProductionRecordId){
              throw "unexpected err pr creation";
            }
            console.log("production created successfully", res);

            if(isCreatingCollabParentPr){


              newProductionOptions["_new_collabParentId_ProductionRecord"] = createdProductionRecordId;
              setIsSubOperationLoading(true);
              productionController()
              .createProduction(
                newProductionOptions,
                currentWorkerCode,
                null,
                idOperation,
                "none",
                popId,
                null,
                preActionsData.current,
                cancelToken
              )
              .then((res: any) => {
                setIsSubOperationLoading(false);
                let responseData = res?.data?.response?.data;
                let createdProductionRecordId = responseData?.Id;
                if(!createdProductionRecordId){
                  throw "unexpected err, collab child creation";
                }

                props.history.push(
                  {
                    pathname:"/feature/production-order/" +
                    productionOrder_productData.Id_ProductionOrder +
                    "/po-product/" +
                    popId +
                    "/production/" +
                    createdProductionRecordId,
                    state:{
                      "preActionsData":preActionsData.current
                    }
                  }
                );
              }).catch((res:any) => {
                setIsSubOperationLoading(false);
                handleCreateProductionCatch(res);
              });
            }else{
              props.history.push(
                {
                  pathname:"/feature/production-order/" +
                  productionOrder_productData.Id_ProductionOrder +
                  "/po-product/" +
                  popId +
                  "/production/" +
                  createdProductionRecordId,
                  state:{
                    "preActionsData":preActionsData.current
                  }
                }
              );
            }

          })
          .catch((res) => {
            setIsOperationLoading(false);
            handleCreateProductionCatch(res);
          });
      }
    }
  }


  const getProductFvs = () => {
    return productionOrder_productData?.productFixedVariables;
  }
  const getOperationOngoingPrs = (idOperationProduct:any, isExternal:any) => {
    let allOnGoingOperations =
    minimizedOperationProductionsData?.payload?.onGoingProductionsPopupData;
    let filteredOnGoingOperations = [];
    if (allOnGoingOperations && allOnGoingOperations.length > 0) {
      for (let i = 0; i < allOnGoingOperations.length; i++) {
        if (
          parseInt(allOnGoingOperations[i]["Id_OperationProduct"]) ===
          idOperationProduct
        ) {
          if(isExternal){
            if(!allOnGoingOperations[i]?.Id_PopSubcontract){
              //not external, skip
              continue;
            }
          }else if(!isExternal){
            if(allOnGoingOperations[i]?.Id_PopSubcontract){
              //must be internal skip if its external
              continue;
            }
          }
          filteredOnGoingOperations.push(allOnGoingOperations[i]);
        }
      }
    }

    return filteredOnGoingOperations;
  }

  const popUpAskNewOrResume = () => {

    //showPopupAskNewOrContinueOperation

    return (
      <PreActionNewOrResumeOperation 
      onClose={() => {
        setShowPopupAskNewOrContinueOperation(null);
      }}
      
      onFinish={(decisionAct:any) => {
        let obj = showPopupAskNewOrContinueOperation.operationObject;
        if(decisionAct === "NEW"){
          operationSelect(obj, showPopupAskNewOrContinueOperation.index);
        }

        let idPop = productionOrderSelectionStore?.product?.Id;
        if(decisionAct === "CONTINUE_PREV"){

          let filteredOnGoingOperations = getOperationOngoingPrs(obj.Id, obj._isExternal);

          let isCollabOperation = obj?.allowOperationPrCollab === 1;
          if (filteredOnGoingOperations.length > 0 || isCollabOperation) {
            /*let filteredMinimizedOperationsData = JSON.parse(
              JSON.stringify(minimizedOperationProductionsData)
            );
            filteredMinimizedOperationsData.payload.onGoingProductionsPopupData = filteredOnGoingOperations;
            filteredMinimizedOperationsData.payload.filters = {Id_OperationProduct:obj.Id};*/
            //let dispatchData = filteredMinimizedOperationsData;
           
         

            let filters:any = {
              Id_OperationProduct:obj.Id,
              Id_ProductionOrder_Product:idPop
            };
            if(isCollabOperation){
              filters.mode = "collab";
            }
            /*
            let dispatchData = {
              type: "SHOW_ON_GOING_PRODUCTIONS_POPUP",
              payload: {
                onGoingProductionsPopupData: [],
                onGoingProductionsPopupSettings: {
                    ...filters
                }
                //onGoingProductionsPopupSettings: { onCloseGoToPath: "/" },
              },
            };
            dispatchCustomPopupsStore(dispatchData);*/
          
            setShowOnGoingPopup({isExternal:obj?._isExternalOperation,filters:{...filters},_obj:showPopupAskNewOrContinueOperation.operationObject,_index:showPopupAskNewOrContinueOperation.index,hideCollabRecords:true});
          }
        }
        
        setShowPopupAskNewOrContinueOperation(null);
      }}/>
    )
  }

  const renderOperation = (obj: any, index: any) => {
  
    if(obj?._shouldHide === true){
      return (<></>)
    }

    let shouldIncludeDailyDistributionData = sessionState?.userSessionData?.operationsDistribution === "DAILY_DISTRIBUTION";
    let tasksDone = !shouldIncludeDailyDistributionData ? obj.UI_WS_productionOrderProductTimesExecutedOperation : obj.tempToday_productionOrderProductTimesExecutedOperation;
    let tasksMustBeDone = !shouldIncludeDailyDistributionData ? obj.UI_WS_productionOrderProductTotalOperationExecutionQuantity : obj.tempToday_productionOrderProductTotalOperationExecutionQuantity;

    return (
      <div>
        <div className={styles.operationTopNotifications}>
          <div className={styles.repositionNotfs}>
            {obj?._isExternalOperation && (
              <OperationExternalTip/>
            )}

            {parseInt(mapOperationsPendingExecutionQty?.[obj.Id]) > 0 && (
                <div className={styles.pendingExecutionsContainer}>
                  <div><PendingActionsIcon/></div>
                  <div>{mapOperationsPendingExecutionQty?.[obj.Id]}</div>
                </div>
            )}
            {mapOperationsStatusIrregularities?.[obj.Id]?.totalOccurrences > 0 && (
                <div className={styles.irregularitiesStatusContainer}>
                  <div><ThumbDownIcon/></div>
                  <div>{mapOperationsStatusIrregularities?.[obj.Id]?.totalOccurrences ? mapOperationsStatusIrregularities?.[obj.Id]?.totalOccurrences :0 }</div>
                </div>
            )}
          </div>

        </div>

        <IonCardHeader
          key={"aao_" + index}
          data-operation-code={obj.code}
          className={`${obj?._isExternalOperation ? styles.externalOperation: ""} ${styles.elemElementOperation} ${
            shouldBeGrayedOut(obj, index)
              ? styles.elemElementOperationDisabled
              : styles.elemElementOperationHover
          } `}
          onClick={() => {
              if(shouldBeGrayedOut(obj, index)){
                return;
              }
              let isCollabOperation = obj?.allowOperationPrCollab === 1;

              //filter out other ongoing Prs, and show only current operation
              let filteredOnGoingOperations = getOperationOngoingPrs(obj.Id, obj?._isExternalOperation); //TODO if _isExternalOperation only filter _isExternal
              if(filteredOnGoingOperations.length > 0 || isCollabOperation){
                setShowPopupAskNewOrContinueOperation({operationObject:obj, index:index});
                return;
              }
              //
              operationSelect(obj, index);
          }}
        >


          <div className={styles.elemBackgroundContainer}>
          
            <IonIcon className={styles.iconElement} slot="icon-only" size="large" icon={hammerOutline} />
          </div>
          <div className={styles.operationElementContent}>
            <div className={styles.elemElementTitle}>
              {/*<b>({obj.code})</b><br></br>*/}
              {obj.name}
            </div>
      
          </div>

          <div className={styles.repositionedOperationsDone} data-tasks-done={tasksDone} data-total-tasks={tasksMustBeDone}>
          <TaskProgressBar showMinimumFill={true} minimumFillPct={10} tasksDone={tasksDone} totalTasks={tasksMustBeDone} width={'100%'} progressBarContainerWidth={'130%'} height={20} fontSize={17} fillerRadius={'0px'}/>
          </div>

        </IonCardHeader>
      </div>

    );
  };

 
  const getPreActionSelectMachineSettings = () => {

    let currentBomProducts = productionOrderSelectionStore?.product?.bomProducts ? productionOrderSelectionStore?.product?.bomProducts : [];
    let productSel = productionOrderSelectionStore?.product;
    let productSelArrayPPVVS = productSel?.arrayProductParameterizedVariables ? productSel?.arrayProductParameterizedVariables : [];

    let operationProductSelected = preActionsStore?.pendingPreActionsOperation?.obj;
    let isShortcutQuickUnavMachine = preActionsStore?.pendingPreActionsOperation?.obj?.machineSelectShortcutQuickMaintenance === 1;
    let settingsObj:any = {};
    if(isShortcutQuickUnavMachine && operationProductSelected && productSel?.Id_Product){
      settingsObj["isShortcutQuickUnavMachine"] = true;
      settingsObj["shortcutQuickUnavMachineData"] = {
        operationProductSelected:{
          Id:operationProductSelected.Id,
          name:operationProductSelected.name,
          code:operationProductSelected.code
        },
        productSelected:{
          Id:productSel.Id_Product,
          name:productSel.name,
          code:productSel.code,
          bomProducts:currentBomProducts,
          arrayProductParameterizedVariableValues:productSelArrayPPVVS
        }
      };
    }
 
    return settingsObj;
  }
  const renderRecorevyFlows = () => {
    //get map
    let mapWithRecoveryFlowsToRender = {} as IMapIrregularityRecoveryFlow;
    let mapKeys = Object.keys(mapRecoveryFlowOrderedOperationsData);
    //check if on each map obj at least one operation is not complete 100/100  if at least one is not then render this flow
    for (let i = 0; i < mapKeys.length; i++) {
      let idOperation: number = parseInt(mapKeys[i]);
      let recoveryFlowObj = mapRecoveryFlowOrderedOperationsData[idOperation];

      let flowOrderedOperations: any = recoveryFlowObj.orderedOperations;
      for (let j = 0; j < flowOrderedOperations.length; j++) {
        let totalToExecute =
          flowOrderedOperations[j]
            ?.UI_WS_productionOrderProductTotalOperationExecutionQuantity;
        let executed =
          flowOrderedOperations[j]
            .UI_WS_productionOrderProductTimesExecutedOperation;
        if (!totalToExecute) {
          totalToExecute = 0;
        }
        if (!executed) {
          executed = 0;
        }
        if (totalToExecute > executed) {
          mapWithRecoveryFlowsToRender[idOperation] = recoveryFlowObj;
          break;
        }
      }
    }

    /*{recoveryFlowObject.orderedOperations.map((obj:any, index:any) => (
      <>
      {renderOperation(obj,index)}
      </>
    ))}*/

    return (
      <>
        {Object.keys(mapWithRecoveryFlowsToRender).map(
          (idOperation: any, index: number) => (
            <div className={styles.flowSection} key={"recFlow_" + index}>
              <HxfDividerWithContent>
                <Chip label="Recovery Flow" />
              </HxfDividerWithContent>
              <div className={styles.operationsContainer}>
                <div className={styles.operationsSelection}>
                  {getMinimizedOperations(
                    mapWithRecoveryFlowsToRender[idOperation].orderedOperations
                  )}

                  {mapWithRecoveryFlowsToRender[
                    idOperation
                  ].orderedOperations.filter((obj: any) => !obj?._shouldHide || obj?._shouldHide !== true).map((obj: any, indexMapRec: any) => (
                    <div key={"indexMapRec_" + indexMapRec + "_idx_" + index}>
                      {renderOperation(obj, index)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        )}
      </>
    );
  };




  return (
    <>
    {showOnGoingPopup && (
      <>
        <OnGoingProductionsPopup
        executionResponsability={showOnGoingPopup.showAll ? "ALL" : (showOnGoingPopup?.isExternal ? "EXTERNAL" : "INTERNAL")}
        hideCollabRecords={showOnGoingPopup?.hideCollabRecords}
        filters={showOnGoingPopup.filters} onClose={() => {
          setShowOnGoingPopup(null);
        }} onOperationSelectStart={(data:any) => {
          let objOperation = showOnGoingPopup?._obj;
          let indexOperation = showOnGoingPopup?._index;
          let candidatePreviousProductionRecords = objOperation.candidatePreviousProductionRecords;
          if(indexOperation !== null && indexOperation !== undefined && objOperation){

            if(data?.isNewCollabWork && data?._new_collabParentId_ProductionRecord){
              objOperation._new_collabParentId_ProductionRecord = data._new_collabParentId_ProductionRecord;
            }

            operationSelect(objOperation,indexOperation);
          }
        
        }}/>
      </>
    )}
    {showPopupAskNewOrContinueOperation && (
      <>
      {popUpAskNewOrResume()}
      </>
    )}
    {preActionsStore?.pendingAction == "PREACT_SELECT_SUBCONTRACT" && (
      <div key={rerenderPreActionSelectMachine}>
        <PreActionSelectPopSubcontract 
          subcontractsSuppliersData={subcontractsSuppliersData}
          operationProduct={
            preActionsStore?.pendingPreActionsOperation?.obj
          }
         
          onClose={() => {
            resetPreActionsData();
          }}
          
          onFinish={(result:any) => {
           
            preActionsData.current = {
              ...preActionsData.current,
              selectedId_PopSubcontract:result?.selectedSource?.Id
            }
            dispatchPreActionsStore({
              type: "SET_PREACTIONS_DATA",
              payload: {
                ...preActionsStore.preActionsData, ...preActionsData.current
              },
            });
            operationSelect(preActionsStore.pendingPreActionsOperation.obj, preActionsStore.pendingPreActionsOperation.index);
          }}

        />
      </div>
    )}
    {preActionsStore?.pendingAction == "PREACT_SELECT_MACHINE" && (
      <div key={rerenderPreActionSelectMachine}>
        <PreActionSelectMachine 
          settings={
            getPreActionSelectMachineSettings()
          }
          Id_ProductionOrder={productionOrderId}
          Id_ProductionOrder_Product={productionOrderProductId}
          onClose={() => {
            resetPreActionsData();
          }}
          
          onFinish={(selectedMachine:any) => {
      
            preActionsData.current = {
              ...preActionsData.current,
              selectedMachine:selectedMachine
            }
            dispatchPreActionsStore({
              type: "SET_PREACTIONS_DATA",
              payload: {
                ...preActionsStore.preActionsData, ...preActionsData.current
              },
            });
            operationSelect(preActionsStore.pendingPreActionsOperation.obj, preActionsStore.pendingPreActionsOperation.index);
          }}

        />
      </div>
    )}


      <ProductionTopInfo />
      <CustomArrowBack
        pushUrl={"/feature/production-order/" + productionOrderData.Id}
      />
      <div className={styles.pageTitle}>
        {productionOrderData.autoCloseOnCompletion && isPopCompleted ? (
          <div>{t('all.operations.finished')}</div>
        ) : (
          <>{t('select.an.operation')}</>
        )}</div>
      <div className={styles.productionStartPreviewContainer}>
        <div className={styles.elemElement}>
          <ProgressBar
            pgValue={Math.round(popCompletedPct)}
            size={"medium"}
            customBorderStyle={{ borderRadius: "25px 25px 0px 0px" }}
          />
          <div className={styles.productionOrderInformation}>
            <div className={styles.productionOrderStatus}>
              <div className={styles.poStatusLabel}>{t("status")}:</div>
              {isPopCompleted ? (
                <div className={styles.poComplete}>{t("finished")}!</div>
              ) : (
                <div className={styles.poInProgress}>{t("in.progress")}...</div>
              )}
            </div>
          </div>

          {productionOrderData.dateEnd !== productionOrderData.dateStart && (
            <div className={styles.productionOrderInformation}>
              <div className={styles.productionOrderLabel}>
                {t("production.order.ending.date")}:
              </div>
              <div className={styles.productionOrderCodeName}>
                {DatesConversionService().tableFormattedDateTimeLabel(productionOrderData.dateEnd)}
              </div>
            </div>
          )}

            {getProductFvs() && (
            <div className={styles.fixedVariablesContainer}>
            {productionOrder_productData.productFixedVariables.map(
              (obj: any, index: any) => (
                <div
                  key={index + "_pfv"}
                  className={styles.productFvInformation}
                >
                  <div className={styles.fixedVariableName}>{obj.name}:</div>
                  <div className={styles.fixedVarValue}>{obj.Value}</div>
                </div>
              )
            )}
          </div>
            )}


            {getParameterizedVariablesInfo()}
        </div>
      </div>

      {productionOrderData.autoCloseOnCompletion && isPopCompleted ? (
        <></>
      ) : (
        <div className={styles.flowSection}>
          <HxfDividerWithContent>
            <Chip label={t("production.flow")} />
          </HxfDividerWithContent>
          {isOperationLoading || isSubOperationLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <LoadingSpinnerImg />
            </div>
          ) : (
            <div className={styles.operationsContainer}>
              <div className={styles.operationsSelection}>
                {getMinimizedOperations(orderedOperationsData)}
                {orderedOperationsData.filter((obj: any) => !obj?._shouldHide || obj?._shouldHide !== true).map((obj: any, index: any) => (
                  <div key={"idx_oper_" + index}>
                    {renderOperation(obj, index)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {Object.keys(mapRecoveryFlowOrderedOperationsData).length === 0 ? (
        <></>
      ) : (
        <>{renderRecorevyFlows()}</>
      )}
    </>
  );
}
export default ProductionStartStage;
