import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../barrel/hooks/usePrevious";
import HxfListboxFix from "../HxfListboxFix/HxfListboxFix";
import styles from "./HxfSelectorField.module.scss";

const filter = createFilterOptions<OptionType>();

interface OptionType {
    inputValue?: string;
    label: string;
    value?: any;
    flagFromUserInput?:boolean; //flag when the current option is a non existing element but comes from user input. Needed info to consider it as a filter
}

interface IHxfSelectorFieldCreationSettings{
  onCustomConditionAllowCreateNew?:any;
}

interface HxfSelectorFieldInterface {
    openByDefault?:boolean;
    allowCreation?:boolean;
    creationSettings?:IHxfSelectorFieldCreationSettings;
    labelPlaceholder?:string;
    placeholder?:string;
    defaultItems?:Array<OptionType>;
    listJsonFunction?:any;
    listJsonOptions?:any;
    labelBuilder?:(args:any) => (any);
    customRenderOption?:(props:any, option:any) => (any); //
    onChange?:(event:any, newValue:any,...args:any) => (void);
    value?:OptionType | null;
    uniqueId:string;
    callbackCacheSearchResults?:(args:Array<any>) => (void);
    error?:boolean;
    onFieldTyping?:(value:any) => (void);
    allowFreeTypePersist?:boolean;
    searchingFilterKey?:string;
    disableInternalFiltering?:boolean; //set to true if using searchingFilterKey, and set to false if using offline selector. by default, this is false because this just text filters the options in the frontoffice but if we are filtering in the backend we can disable this internal filter
    applyValueAsFilterIfString?:boolean;
    disabled?:boolean;
    refTextfield?:any;
    onBlur?:any;
    disableClearable?:any;
    style?:any;
    centerInputText?:any;
    

    dataInputFieldAttributeValue?:any;
    disableDefaultItemsCaching?:boolean;

    customNoOptionsLabel?:any;
}

function HxfSelectorField(props:HxfSelectorFieldInterface){
    //const [curValue, setCurValue] = React.useState<OptionType | null>(null);
    const [curValue, setCurValue] = React.useState<OptionType | null>(props.value !== undefined ? props.value : null);
    const passedValue = props?.value;
    const previousPassedValue = usePrevious(passedValue);
    const [loadedItems, setLoadedItems] = React.useState<Array<OptionType>>([]);
  
    const [didMount, setDidMount] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(true);
  
    const [hasLoadedSomething, setHasLoadedSomething] = React.useState(false);
    const [startedLoading, setStartedLoading] = React.useState(true);

    const [isLoadMoreBottomScrollEnabled, setIsLoadMoreBottomScrollEnabled] = React.useState(true);

    const [selectorWorkAroundEnabled, setSelectorWorkAroundEnabled] = React.useState(false);
    const {t} = useHxfTranslation();
    const [loadingIndex, setLoadingIndex] = React.useState(0);

    const [searchingFilter, setSearchingFilter] = React.useState("");
    const [latestSearchfilter, setLatestSearchFilter] = React.useState("");
    const initialListJsonOptions = {"page":0,"size":25};
    //listjson data
    const listJsonOptions = React.useRef(props?.listJsonOptions ? {...initialListJsonOptions, ...props.listJsonOptions} : initialListJsonOptions);
 
    const [rerenderKey, setRerenderKey] = React.useState(0);
    const previousListJsonOptionsListenerState = usePrevious(props?.listJsonOptions);

    const [time, setTime] = React.useState(0);
    const prevTime = usePrevious(time);

    const [openState, setOpenState] = React.useState(false);

    const timerReloadItems = React.useRef<any>(null);
    const [itemsReloaderListener, setItemsReloaderListener] = React.useState(0);
    const itemsReloadKeyDelay = 125;
    const prevItemsReloaderListener = usePrevious(itemsReloaderListener);

    React.useEffect(() => {
      if(props?.disableDefaultItemsCaching){
          if(JSON.stringify(props.defaultItems) !== JSON.stringify(loadedItems)){
            setLoadedItems(props?.defaultItems ? props?.defaultItems : []);
          }
      }
    },[props.defaultItems]);

    React.useEffect(() => {
      if(itemsReloaderListener && itemsReloaderListener > 0 && prevItemsReloaderListener !== itemsReloaderListener){
          loadRemoteItems();
      }
    },[itemsReloaderListener]);

    const getSx = () => {
      let sxElem = {input:{}};
      if(props?.centerInputText){
          sxElem.input = {textAlign: "center"};
      }
      return sxElem;
  }

    const clearLoadingElementIfExists = React.useCallback(() => {
      let newElems = [];
      let found = false;
      for(let i = 0; i < loadedItems.length; i++){
        let stringConv = String(loadedItems[i]?.value);

        if(!stringConv.includes("loading-elem")){
          newElems.push(loadedItems[i]);
          
        }else{
          console.log("CLEARING");
          found = true;
        }
      }
      if(found){
        
        setLoadedItems(newElems);
      }
      

    },[loadedItems]);


    const getFieldRender = (params:any) => {


      
     

      return (
        <div>
          
       <TextField autoFocus={props?.openByDefault} onFocus={() => {
    
       }} onBlur={() => {
         if(props?.onBlur){
           props.onBlur();
         }
       }} inputRef={props?.refTextfield} onChange={(evt) => {
         if(props.onFieldTyping){
           props.onFieldTyping(evt);
         }
       
         if(props.allowFreeTypePersist){
           setCurValue({
             label: evt.target.value, value:evt.target.value, flagFromUserInput:true
           });

           if(props.onChange){
             props.onChange(event, evt.target.value, false);
           }
         }

         handleChangeSearchFilter(evt.target.value);

         }} error={props.error ? props.error : false} {...params} label={props?.labelPlaceholder !== null ? props.labelPlaceholder : "Select an option"} placeholder={props.placeholder ? props.placeholder : null}  sx={getSx()}/>
         <div style={{position:'relative'}}><div className={styles.verticalBar}></div></div>
        </div>

     )
    
   }


    const resetPagging = () => {
      listJsonOptions.current.page = 0;

    }

    const handleChangeSearchFilter = (newFilterWord:any) => {
      console.log("CUR VAL: ", curValue);
    
      if(props.searchingFilterKey){

        if(props.allowFreeTypePersist){
          


          newFilterWord = curValue?.label;
          if(!newFilterWord){
            newFilterWord = "";
          }
          /*if(curValue !== null && curValue?.flagFromUserInput){
             newFilterWord = curValue.value;
            
          }*/
  
          if(newFilterWord !== listJsonOptions.current[props.searchingFilterKey]){
            //setSearchingFilter(newFilterWord);
            console.log(" CHANGING FILTER FROM: ",listJsonOptions.current[props.searchingFilterKey]);
            console.log(" CHANGING FILTER TO: ", newFilterWord);
            listJsonOptions.current[props.searchingFilterKey] = newFilterWord;
            resetPagging();
            console.log("rem its called x2");
            
            //loadRemoteItems();
            if(timerReloadItems.current){
              clearTimeout(timerReloadItems.current);
            }
            
            timerReloadItems.current = setTimeout( () => {
              setItemsReloaderListener(itemsReloaderListener + 1);
            },itemsReloadKeyDelay);
          }
  
        }else{
          if(newFilterWord !== listJsonOptions.current[props.searchingFilterKey]){
            //setSearchingFilter(newFilterWord);
            console.log(" CHANGING FILTER TO: ", newFilterWord);
            listJsonOptions.current[props.searchingFilterKey] = newFilterWord;
            resetPagging();
            console.log("rem its called x3");
            //loadRemoteItems();
            if(timerReloadItems.current){
              clearTimeout(timerReloadItems.current);
            }
            
            timerReloadItems.current = setTimeout( () => {
              setItemsReloaderListener(itemsReloaderListener + 1);
            },itemsReloadKeyDelay);
          }
        }


      }
      


    }

    const getFilterOptions = (options:any, params:any) => {

      let filtered = filter(options, params);  
      /*console.log("Filter options: ", options);
      console.log(" Filterp aramS: ", params);*/
      if(props.disableInternalFiltering){
        //console.log(" Params: " + JSON.stringify(params));

        let manipulatedParams = {...params};
        manipulatedParams.inputValue = "";
        filtered = filter(options, manipulatedParams);
        //console.log(" xParams: " + JSON.stringify(params));
      }
        
      
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option:any) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
          if(props.allowCreation){
            console.log("my filtered ",filtered);

            let customAllowed = false;
            if(props.creationSettings?.onCustomConditionAllowCreateNew){
              customAllowed = props.creationSettings?.onCustomConditionAllowCreateNew(filtered,inputValue);
            }
            let allowCreate = ((filtered.length === 0) || customAllowed) && props.allowCreation;
            if(allowCreate){

             let newPush = {
               inputValue,
               label: t('multi.selector.field.create') + " \"" + inputValue + "\"",
               value:0
             };
             console.log("NEWPUSH: ",newPush);
             filtered.push(newPush);
            }

         }
            
        }
        
      
        if(props.searchingFilterKey){
         
         // handleChangeSearchFilter(inputValue); //inputvalue
        }


        
        return filtered;
      }

      const loadRemoteItems = React.useCallback(() => {
        console.log("Loading remote items." , listJsonOptions.current);
  
        props.listJsonFunction(listJsonOptions.current).then((res:any) => {
          setIsLoading(false);
         
          console.log("success", res);
          let loadedData = res.data.response.data.return;
          let totalSize = res.data.response.data.totalSize;
          console.log(loadedData);
          let currentLoadedItems = loadedItems;
          let newLoadedItems = [];
          for(let i = 0; i<loadedData.length; i++){

            let labelStr = "";
            if(props.labelBuilder){
              labelStr = props.labelBuilder(loadedData[i]);
            }else{
              labelStr = "(" + loadedData[i].code + ") " + loadedData[i].name;
            }
            newLoadedItems.push({"label":labelStr,"value":loadedData[i].Id,"fetchedData":loadedData[i]});
          }

          if(props.callbackCacheSearchResults){
         
            props.callbackCacheSearchResults(loadedData);
          }
          let countLoadingElementsFound = 0;
          console.log("PAG ATUAL: ",listJsonOptions.current.page);
          if( listJsonOptions.current.page === 0){
            currentLoadedItems = [];
          }
          for(let i = 0; i < currentLoadedItems.length; i++){
            let stringConv = String(currentLoadedItems[i]?.value);
    
            if(!stringConv.includes("loading-elem")){
            
             
            }else{
              countLoadingElementsFound++;
            }
          }

          let loadedElemsFull = currentLoadedItems.concat(newLoadedItems);
          
          let keepCurrentLoadedItems = true;
          if(props.searchingFilterKey){
            if(listJsonOptions.current[props.searchingFilterKey] !== latestSearchfilter){
              keepCurrentLoadedItems = false;
            }
          }
          let pageSize = listJsonOptions.current.size;
          let hasMore = false;
        
          if(loadedElemsFull.length - countLoadingElementsFound < totalSize){
            setIsLoadMoreBottomScrollEnabled(true);
            hasMore = true;
          }else if(isLoadMoreBottomScrollEnabled){
            setIsLoadMoreBottomScrollEnabled(false);

          }


          let clearedLoadedItems = [];
          if(keepCurrentLoadedItems){
            for(let i = 0; i < currentLoadedItems.length; i++){
              let stringConv = String(currentLoadedItems[i]?.value);
      
              if(!stringConv.includes("loading-elem")){
                clearedLoadedItems.push(currentLoadedItems[i]);
                console.log("CLEAR X2");
                
              }
            }
          }



          if(hasMore){
            newLoadedItems.push({label:"loading-elem-" + loadingIndex, "value":"loading-elem"});
          }
          loadedElemsFull = clearedLoadedItems.concat(newLoadedItems);
          
          setLoadingIndex(loadingIndex+1);
          console.log("Setting loaded items: ", loadedElemsFull);
          
          setLoadedItems(loadedElemsFull);
          setHasLoadedSomething(true);
          if(props.searchingFilterKey){
            //remind of the last used searching filter
            setLatestSearchFilter(listJsonOptions.current[props.searchingFilterKey]);
          }
          
        }).catch((res:any) => {
          console.log(res);
          console.log("fail");
        });
      }, [props, loadedItems,loadingIndex,isLoadMoreBottomScrollEnabled, latestSearchfilter]);



      React.useEffect(() => {
        
        if(props?.listJsonOptions !== previousListJsonOptionsListenerState && previousListJsonOptionsListenerState){
          console.log("detected listjson options change, rerendering");
          listJsonOptions.current = {...listJsonOptions.current, ...props.listJsonOptions};
          setRerenderKey(rerenderKey + 1);
        }
      },[props.listJsonOptions, previousListJsonOptionsListenerState,rerenderKey]);

      React.useEffect(() => {

            if(!selectorWorkAroundEnabled){
              return;
            }
            const timer = setTimeout(() => {
              if(props.listJsonFunction){
                console.log("TICKING");
                let selectorFieldId = props.uniqueId + "-listbox";
                let scrollableSelectArea = document.getElementById(selectorFieldId);
                //load more or not
                if(isLoadMoreBottomScrollEnabled){
                  if(scrollableSelectArea !== null){
                    let res =  (scrollableSelectArea.scrollHeight - scrollableSelectArea.offsetHeight);
                    let acceptableMinusValue = 20;
                    if( scrollableSelectArea.scrollTop >= res-acceptableMinusValue)
                    {
                      console.log("SHOULD SCROLL MORE!");
                      setIsLoadMoreBottomScrollEnabled(false);
                      listJsonOptions.current.page = listJsonOptions.current.page + 1;
                      clearLoadingElementIfExists();
                      console.log("rem its called");
                      loadRemoteItems();
                     
                    }
                  }
                }
              }
              setTime(time + 1);
            }, 1000);

          
          

          return () => {
            clearTimeout(timer);
          };  
        


      }, [time,prevTime, clearLoadingElementIfExists,selectorWorkAroundEnabled,isLoadMoreBottomScrollEnabled, loadRemoteItems, props.listJsonFunction, props.uniqueId]);


      React.useEffect(() => {
        if(previousPassedValue !== passedValue){

          let newVal = null;
          console.log("passedVal:",passedValue);
          if(passedValue === undefined || passedValue?.label === ""){
            newVal = null;
          }else{
            newVal = passedValue;
          }
          setCurValue(newVal);
        }
      },[passedValue, previousPassedValue]);
  
      // eslint-disable-next-line
      const callOnOpen = () => {
        //------- load filters handling

        if(props.searchingFilterKey){
          if(curValue !== undefined && curValue?.value !== "" && curValue?.flagFromUserInput){
            listJsonOptions.current[props.searchingFilterKey] = curValue.value;
          }
          else{
            console.log("CLEARING FILTER");
            listJsonOptions.current[props.searchingFilterKey] = "";
          }
        }


        //--- ticks
        setSelectorWorkAroundEnabled(true);
        if(startedLoading && isLoading){
          if(props.listJsonFunction){
            console.log("rem its called x1");
            
            if(props?.allowFreeTypePersist && props?.searchingFilterKey){
        
              let filteringWith = curValue?.label;
              if(!filteringWith){
                filteringWith = "";
              }
              listJsonOptions.current[props.searchingFilterKey] = filteringWith;
            }

        
            loadRemoteItems();
            
            
            
            
            setStartedLoading(false);
          }else{
            setStartedLoading(false),
            setIsLoading(false);
          }
        }
      }

      React.useEffect(() => {
          if(!didMount){
            if(props.defaultItems){
         
              setLoadedItems(props.defaultItems);
            }

            if(props.openByDefault){
              setOpenState(true);
              callOnOpen();
            }
           
            setDidMount(true);
          }
        
      },[didMount, props.defaultItems, loadRemoteItems, props.listJsonFunction, callOnOpen, props.openByDefault]);


      const isDynamicRemoveInput = () => {
        return props?.listJsonFunction ? true : false;
      }

  
      const getProperNoOptionsText = () => {
  
        if(isDynamicRemoveInput() && !hasLoadedSomething){
          return t('loading') + "...";
        }
  
        if(props?.customNoOptionsLabel){
          return props.customNoOptionsLabel;
        }
        return  t('no.options');
      }


    return (
    <>
    
    <Autocomplete
      noOptionsText={getProperNoOptionsText()}
     style={props?.style}
    /*freeSolo={props.allowCreation}
    autoSelect={props.allowCreation}*/

      disableClearable={props?.disableClearable}
      key={rerenderKey}
      disabled={props?.disabled}
      getOptionDisabled={(option) =>{
        let stringConv = String(option?.value);
       
        if(option !== undefined && stringConv.includes("loading-elem")){
          return true;
        }
        return false;
      }
      
    }
    ListboxComponent={HxfListboxFix}
      onContextMenu={() => {
        console.log("context menu trigered");
      }}
      open={openState}
      onOpen={() => {
    
        if(props?.disabled){
          return; //bug fix on corner clicking
        }
        setOpenState(true);
        callOnOpen();


      }}
      onClose={
        () => {
          setOpenState(false);
          if(props.defaultItems){
     
            setLoadedItems(props.defaultItems);
          }else{
            setLoadedItems([]);
          }
          setLoadingIndex(0);
          resetPagging();
          
          setStartedLoading(true);
          setIsLoading(true);
          setSelectorWorkAroundEnabled(false);
          setHasLoadedSomething(false);
          setTimeout(() => {
            if( document?.activeElement){
              console.log("Forced blur on selection");
              let elem:any = document?.activeElement;
              elem.blur();
            }
          }, 0);
        }
      }
      loading={isLoading}
      id={props.uniqueId}
      value={curValue}
      onChange={(event, newValue) => {
       
        console.log("CHANGING ", newValue);
        if (typeof newValue === 'string') {
          setCurValue({
            label: newValue
          });

        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setCurValue({
            label: newValue.inputValue
          });
        } else {
            //setCurValue(newValue);
        }
        console.log(event);
        if(props.onChange){
          console.log("Changing to : " + newValue);
          props.onChange(event, newValue);
        }
       
      }}
      
      filterOptions={getFilterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      isOptionEqualToValue={(option, value) => {
       
        return option.value === value.value || value === null;
      }}
      options={loadedItems}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        
        if(option === undefined || option.label === undefined || option?.label === null){
          return "";
        }
       
        if(option !== undefined && option.label !== undefined && option?.label.includes("loading-elem")){
            return option.label = t("loading");
        }
        

        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option?.label ? option.label : option.inputValue;
        }

        // Regular option
        return option.label;
      }}
      renderOption={(propsx:any, option) => {
        if(props?.customRenderOption){
          return props.customRenderOption(propsx,option);
        }


        if(option?.value){
          propsx["data-option-value"] = option.value;
        }
        
        
        return (<li {...propsx}>{option.label}</li>)
      }}

      data-input-field={props.dataInputFieldAttributeValue}
      renderInput={getFieldRender}
    />

    </>)
}

export default HxfSelectorField;