import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useCallback, useEffect, useRef, useState } from "react";
import buyOrdersController from "../../../../../../barrel/controllers/buyOrdersController";
import useStateCallback from "../../../../../../barrel/hooks/useStateCallback";
import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomErrorNotice from "../../../../../CustomElements/CustomErrorNotice/CustomErrorNotice";
import CustomSearchBar from "../../../../../CustomElements/CustomSearchBar/CustomSearchBar";
import ErrorNoticeRequiresNetworkLoadObject from "../../../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import HxfInfiniteScroll from "../../../../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
import ProgressBar from "../../../../../CustomElements/ProgressBar/ProgressBar";
import InAppTemplate from "../../../../../InAppTemplate/InAppTemplate";
import styles from "./ReceivingsBuyOrdersSelection.module.scss";
import { searchCircleOutline } from "ionicons/icons";
import DatesConversionService from "../../../../../../barrel/services/datesConversionService";
import CustomAppliedFilterChip from "../../../../../CustomElements/CustomAppliedFilterChip/CustomAppliedFilterChip";
import CustomUnavailableErrorNotice from "../../../../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
import useSyncSettings from "../../../../../../barrel/hooks/useSyncSettings";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { t } from "i18next";
function ReceivingsBuyOrdersSelection(props) {
  const syncSettings = useSyncSettings();

  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);
  const posContainerRef = useRef(null);
  const sizePage = 25;

  const loadedObjsRef = useRef(null);

  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const infiniteScrollEnabledRef = useRef(false);
  const allowedInfiniteScrollLoadPageRef = useRef(0);
  const [indexReRender, setIndexReRender] = useState(0);

  const [searchbarInput, setSearchbarInput] = useState("");
  const timerStartSearching = useRef(0);

  const buyOrdersSearchFilters = useRef({});
  function isHidden(el) {
    return el.offsetParent === null;
  }

  const initiateFilterCode = (val) => {
    setSearchbarInput(val);
    clearTimeout(timerStartSearching.current); //stops spam requests
    timerStartSearching.current = setTimeout(
      () => {
        allowedInfiniteScrollLoadPageRef.current = 0;
        buyOrdersSearchFilters.current = {
          ...buyOrdersSearchFilters.current,
          searchByCode: val,
        };
        loadedObjsRef.current = null;
        loadMore();
      },
      500,
      val
    );
  };
  const loadMore = useCallback(() => {
    let currentPage = allowedInfiniteScrollLoadPageRef.current;

    buyOrdersController()
      .getBuyOrders({
        page: currentPage,
        size: sizePage,
        openOnly: true,
        ...buyOrdersSearchFilters.current,
      })
      .then((resp) => {
        syncSettings.check(resp);
        let posData = resp.data.response.data.return;
        let posTotalSize = resp.data.response.data.totalSize;
        let posExtraData = resp.data.response.data.extra;

        if (currentPage === 0) {
          //first load only
          //loads the extraData that loads in the backend only when the page = 0
          //this is done to avoid double requests just to gather extra unrelated data
        }

        let newArrayLoadedObjs = [];

        if (loadedObjsRef.current !== null) {
          newArrayLoadedObjs = [...loadedObjsRef.current, ...posData];
        } else {
          newArrayLoadedObjs = posData;
        }

        loadedObjsRef.current = newArrayLoadedObjs;

        let hasMoreElements =
          newArrayLoadedObjs.length < posTotalSize && posData.length !== 0;
        if (hasMoreElements) {
          allowedInfiniteScrollLoadPageRef.current =
            allowedInfiniteScrollLoadPageRef.current + 1;
          infiniteScrollEnabledRef.current = true;
        } else {
          infiniteScrollEnabledRef.current = false;
        }
        if (currentPage === 0) {
          setLoadingPage(false);
        }
        //setLoadedObjs(loadedObjsRef.current);
        setIsLoadingMore(false);
        setIndexReRender(indexReRender + 1);
      })
      .catch((resp) => {
        console.log("THE ERROR: ", resp);
        if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
          if (failedToLoadPosError) {
            setFailedToLoadPosError(false);
          }
          setRequiresNetworkLoadError(true);
        } else {
          if (requiresNetworkLoadError) {
            setRequiresNetworkLoadError(false);
          }
          setFailedToLoadPosError(true);
        }
        console.log("FAILED::", resp);

        setLoadingPage(false);
      });
  }, [
    syncSettings,
    failedToLoadPosError,
    indexReRender,
    requiresNetworkLoadError,
  ]);

  useEffect(() => {
    if (!didMount) {
      loadMore();
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,
    requiresNetworkLoadError,
    sessionState,
    loadMore,
  ]);

  return (
    <InAppTemplate>
      <CustomArrowBack pushUrl="/feature/receivings" />
      <div className={styles.pageTitle}>{t("buyorders")}</div>

      <div className={styles.settingsContainer}>
        <div className={styles.customSearchBarContainer}>
          <CustomSearchBar
            value={searchbarInput}
            onResetTextClick={() => {
              initiateFilterCode("");
            }}
            onChange={(evt) => {
              initiateFilterCode(evt.target.value);
            }}
            iconElement={
              <div className={styles.searchIconStyles}>
                <IonIcon icon={searchCircleOutline} />
              </div>
            }
            placeholder={t("buyorder.code")}
          />
        </div>
      </div>
      <div className={styles.buyOrdersContainer}>
        <div
          id="buyOrdersSelectionDiv"
          key={"rerender_ref_selection_" + indexReRender}
          className={styles.buyOrdersSelection}
          ref={posContainerRef}
        >
          {!failedToLoadPosError && loadedObjsRef.current !== null ? (
            <>
              {loadedObjsRef?.current?.length === 0 && (
                  <div style={{display:'flex',justifyContent:'center'}}>{t('no.purchase.orders.available')}</div>
              )}
              {loadedObjsRef.current.map((obj, index) => (
                <IonCardHeader
                  key={index}
                  className={styles.elemElement}
                  onClick={() => {
                    props.history.push({
                      pathname: "/feature/receivings/receiving-orders",
                      state: {
                        applyFilters: [
                          {
                            filterKey: "Id_BuyOrder",
                            Id_BuyOrder: obj.Id,
                            orderCode: obj.code,
                            orderName: obj.name,
                          },
                        ],
                      },
                    });
                  }}
                >
                  <div className={styles.elemBackgroundContainer}>
                    <ShoppingBasketIcon />
                  </div>
                  <div className={styles.orderNameCodeContainer}>
                    <div>
                      <b>{t("order")}:</b>
                    </div>
                    <div className={styles.orderNameCode}>
                      <div
                        className={`${styles.singleLineOverFlowElipsis} ${styles.orderLabel}`}
                      >
                        ({obj.code})
                      </div>
                      <div
                        className={`${styles.singleLineOverFlowElipsis} ${styles.orderLabel}`}
                      >
                        {obj.name}
                      </div>
                    </div>
                  </div>
                  <div className={styles.creationDateContainer}>
                    <div>
                      <b>{t("date.created")}:</b>
                    </div>
                    <div>
                      {DatesConversionService().formattedDate(obj.dateCreated)}
                    </div>
                  </div>
                  <div className={styles.progressBarContainer}>
                    <ProgressBar pgValue={obj.buyOrder_completePct} />
                  </div>
                </IonCardHeader>
              ))}{" "}
            </>
          ) : (
            <></>
          )}

          {isLoadingMore && (
            <IonCardHeader className={styles.elemElement} onClick={() => {}}>
              <div className={styles.elemBackgroundContainer}>
                <LocalShippingIcon />
              </div>
              <IonCardSubtitle>
                <div className={styles.elemElementTitle}>Loading...</div>
              </IonCardSubtitle>
              <IonCardTitle className={styles.elemElementDescription}>
                <IonSpinner />
              </IonCardTitle>
            </IonCardHeader>
          )}

          <HxfInfiniteScroll
            containerElementRef={posContainerRef}
            requiresContainerHavingScrollbar={true}
            onLoadMore={() => {
              /*let allowingVal = allowedInfiniteScrollLoadPageRef.current + 1;
            
              if(allowingVal > 7){
                return;
              }
              console.log("allowing more :", allowingVal);
               allowedInfiniteScrollLoadPageRef.current = allowedInfiniteScrollLoadPageRef.current + 1;
              */

              if (infiniteScrollEnabledRef.current) {
                setIsLoadingMore(true);
                loadMore();
              }
            }}
            allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
          />
        </div>
      </div>
      {failedToLoadPosError ? (
        <div className={styles.errorArea}>
          <CustomUnavailableErrorNotice />
        </div>
      ) : (
        <></>
      )}

      {requiresNetworkLoadError ? (
        <div className={styles.errorArea}>
          <ErrorNoticeRequiresNetworkLoadObject />
        </div>
      ) : (
        <></>
      )}
    </InAppTemplate>
  );
}

export default ReceivingsBuyOrdersSelection;
