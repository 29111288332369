import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline, documentOutline, fileTrayOutline } from "ionicons/icons";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import SimpleContentPopup from "../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import styles from "./viewFilesPopup.module.scss";
import Ripples from "react-ripples";
import {
    IonButtons,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonPage,
    IonItem,
    IonInput,
  } from '@ionic/react';
import HxfIonPopup from "../../ProductionUI/HxfIonPopup/HxfIonPopup";
import spaceDriveController from "../../../../barrel/controllers/spaceDriveController";
import useLocalStorageHandler from "../../../../barrel/hooks/useLocalStorageHandler";
import { useRef, useState } from "react";
import ViewFileImage from "./contentDisplayers/viewFileImage";
import ViewFileVideo from "./contentDisplayers/viewFileVideo";
import MobileWrapperCommunicationService from "../../../../barrel/services/mobileWrapperCommunicationService";
import SystemService from "../../../../barrel/services/systemService";
export interface ISpaceDriveFile{
    Id?:any;
    visibleFileName?:any;
    Id_SpaceDriveFolder?:any;
    fileContent?:any;
    fileType?:null | "image" | "pdf" | "video";
    totalChunks?:number;
    chunkIndex?:number;
    _tempId?:any;
    parent_Id_SpaceDriveFile?:any;
    storedBytesSize?:any;
    creationDate?:any;
    systemLinkId_OperationProduct?:any;
    isAssociatedWithAnOperation?:any;
    isAssociatedWithMultipleOperations?:any;
}

interface IRenderFile{
    mode:'pdf'|'media',
    url:any,
    file:ISpaceDriveFile
}

interface IPopup {
  onClosePopup: any;
  files:ISpaceDriveFile[];
  Id_OperationProduct:any;
}

function ViewFilesPopup(props: IPopup) {
  const downloadingRef = useRef<any>(null);
  const feedbackService = useFeedbackService();
  const {t} = useHxfTranslation();
  const myLocalStorage = useLocalStorageHandler();
  const [rerenderDownloadKey, setRerenderDownloadKey] = useState(0); 
  const downloadFileUrl = useRef("");
  const [downloadingProgress, setDownloadingProgress] = useState<any>(-1);

  const [renderFile, setRenderFile] = useState<IRenderFile | null>(null);

  const openFile = (file:ISpaceDriveFile) => {
    let currentWorkerCode = myLocalStorage.getWorkerIdentificationCode();

    let isEmbedMode = false;
    let isContentPlayer = false;
    if(file.fileType === "pdf"){
        isEmbedMode = true;
    }else if(file.fileType === "image" || file.fileType === "video"){
        isContentPlayer = true;
    }

    myLocalStorage.getWorkerIdentificationCode();
    spaceDriveController().generateDownloadUrl({isEmbed:isEmbedMode,Id_SpaceDriveFile:file.Id,workerCode:currentWorkerCode,Id_OperationProduct:props.Id_OperationProduct}).then((res) => {

        let downloadUrl = res?.data?.urlDownload;
        if(!downloadUrl){
            throw "unexpected";
        }

        //cancel previous pending download if exists
        if(downloadingRef?.current){
            downloadingRef.current.abort();
            setDownloadingProgress(-1);
        }

        if(isEmbedMode && !MobileWrapperCommunicationService().isDeviceMobileWrapper() && !SystemService().isMobile()){
            //render in a frame
            setRenderFile({mode:'pdf',url: downloadUrl,file:file});
        }else if(isContentPlayer){
            setRenderFile({mode:'media',url: downloadUrl,file:file});
        
        }else{
            saveOrOpenBlob(downloadUrl, file.visibleFileName);
        }



      
        //domDownloadFile(downloadUrl, file.visibleFileName);
       /* downloadFileUrl.current = downloadUrl;
        
        setRerenderDownloadKey(rerenderDownloadKey+1);*/
    }).catch((res ) => {
        feedbackService.notifyToast(t('generic.critical.error'), "error");
    });
  }


function saveOrOpenBlob(url:any, blobName:any) {
    //todo test mobile with HTTPS if still not working simply open url target blank
    let blob:any;
    let xmlHTTP = new XMLHttpRequest();
    xmlHTTP.open('GET', url, true);
    xmlHTTP.responseType = 'arraybuffer';
    xmlHTTP.onload = function(e) {
        if (xmlHTTP.status === 200) {
            blob = new Blob([this.response]);   
        }else{
            console.log("error failed to find file err234");
        }
    };
    xmlHTTP.onprogress = function(pr) {
     
        if(pr?.loaded){
            if(pr.loaded === 0){
                setDownloadingProgress(0);
            }else{
                let pg = pr.loaded / pr.total;
                setDownloadingProgress(pg*100);
            }
        }
   
    };
    xmlHTTP.onloadend = function(e){
        let fileName = blobName;
        let tempEl:any = document.createElement("a");
        document.body.appendChild(tempEl);
        tempEl.style = "display: none";
        url = window.URL.createObjectURL(blob);
        tempEl.href = url;
        tempEl.download = fileName;
        tempEl.click();
        window.URL.revokeObjectURL(url);
    }
    xmlHTTP.send();
    downloadingRef.current = xmlHTTP;
}
  
function domDownloadFile(url:any, fileName:any) {

    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    // Ensure the element is not appended to the DOM
    link.style.display = 'none';

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger a click on the link
    link.click();

    // Remove the link from the DOM after the download
    document.body.removeChild(link);
}

const getRenderUrl = () => {
    
    return renderFile?.url;
}

  const buildIframeDownloadFileUrl = () => {
    return downloadFileUrl.current;
  }

  if(renderFile?.mode === "pdf"){
    
    return (
        <HxfIonPopup title={t('view.files')} modelSettings={{mode:'fullscreen',disableOverflowScroll:true}} onClose={() => {
           setRenderFile(null);
        }} content={(
            <>
                <div className={styles.renderFileFrameContainer}>
                    <iframe   className={styles.renderFileFrame} id="pdfViewer" width="100%" height="100%"  src={renderFile.url}></iframe>
                </div>
            </>
        )}/>
      );
  }

  if(renderFile?.mode === "media"){
    return (
        <HxfIonPopup title={t('view.files')}  modelSettings={{mode:'fullscreen'}} onClose={() => {
           setRenderFile(null);
        }} content={(
            <>
                {renderFile.file.fileType === "image" && (
                    <div className={styles.imageMediaContainer}>
                        
                        <ViewFileImage disableZoomFunctionalityExperimental={true} imageUrl={renderFile.url}/>
                    </div>
                )}
                {renderFile.file.fileType === "video" && (
                    
                        <div className={styles.videoMediaContainer}>
                            <ViewFileVideo url={renderFile.url}/>
                        </div>
                   
                )}
            </>
        )}/>
      );
  }

  return (
    <HxfIonPopup title={t('view.files')}  onClose={() => {
        props.onClosePopup();
    }} content={(
        <>
        {(downloadingProgress != -1 && downloadingProgress !== 100) && (
            <div className={styles.downloadingBar}>
                <div className={styles.downloadedProgress} style={{width:downloadingProgress + "%"}}></div>
                <div className={styles.pgLabel}>{parseInt(downloadingProgress)}%</div>
            </div>
        )}
       <div  className={styles.centerContent}>
         {rerenderDownloadKey > 0 && (
            <div style={{display:'none'}} key={rerenderDownloadKey}>
                <iframe title="fileDoc" src={buildIframeDownloadFileUrl()} width="0" height="0"></iframe>
            </div>
          )}

        <div className={styles.itemsContainer}>
            {props.files.map((obj:ISpaceDriveFile, index:number) => (
                <div key={"sdf_" + index} className={styles.fileContainer}>
                        <Ripples className={styles.ripples}>

                            <div className={styles.fileBtn} onClick={() => {
                                openFile(obj);
                            }}>
                                <div className={styles.iconFileStyle}>
                                <IonIcon slot="icon-only" size="large" icon={documentOutline} />
                                </div>
                                
                                <div className={styles.fileBtnLabel}>
                                    {obj.visibleFileName}
                                </div>
                                
                            </div>
                        </Ripples>

                </div>
            ))}

        </div>
    </div>
    </>
    )}/>

  );
}

export default ViewFilesPopup;
