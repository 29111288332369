import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner
} from "@ionic/react";
import SellIcon from '@mui/icons-material/Sell';
import { searchCircleOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import salesOrdersController from "../../../../../../barrel/controllers/salesOrdersController";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../../../../barrel/hooks/usePrevious";
import useSyncSettings from "../../../../../../barrel/hooks/useSyncSettings";
import DatesConversionService from "../../../../../../barrel/services/datesConversionService";
import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomSearchBar from "../../../../../CustomElements/CustomSearchBar/CustomSearchBar";
import CustomUnavailableErrorNotice from "../../../../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
import ErrorNoticeRequiresNetworkLoadObject from "../../../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import HxfInfiniteScroll from "../../../../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
import ProgressBar from "../../../../../CustomElements/ProgressBar/ProgressBar";
import InAppTemplate from "../../../../../InAppTemplate/InAppTemplate";
import styles from "./ShippingsSalesOrdersSelection.module.scss";
function ShippingsSalesOrdersSelection(props) {
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);
  const previousDidMount = usePrevious(didMount);
  const [filtersApplied, setFiltersApplied] = useState([]);
  const previousFiltersApplied = usePrevious(filtersApplied);
  const syncSettings = useSyncSettings();
  const sizePage = 25;
  const {t} = useHxfTranslation();
  const loadedObjsRef = useRef(null);
  const isRestartingSearch = useRef(false);

  const [loadedObjs, setLoadedObjs] = useState(null);
  
  const posContainerRef = useRef(null);
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loadMoreRequestingIndex = useRef(0);


  const infiniteScrollEnabledRef = useRef(false);
  const allowedInfiniteScrollLoadPageRef = useRef(0);
  const [indexReRender, setIndexReRender] = useState(0);

  const [searchbarInput, setSearchbarInput] = useState("");
  const timerStartSearching = useRef(0);

  const salesOrdersSearchFilters = useRef({});
  function isHidden(el) {
    return el.offsetParent === null;
  }

  const initiateFilterCode = (val) => {
    loadMoreRequestingIndex.current = loadMoreRequestingIndex.current + 1;
    let requestinIndex = loadMoreRequestingIndex.current;
    setSearchbarInput(val);
    clearTimeout(timerStartSearching.current); //stops spam requests
    timerStartSearching.current = setTimeout(
      () => {
        allowedInfiniteScrollLoadPageRef.current = 0;
        salesOrdersSearchFilters.current = {
          ...salesOrdersSearchFilters.current,
          searchByCode: val,
        };
        //loadedObjsRef.current = null;
        //loadMore({requestinIndex:requestinIndex});

        restartSearch(requestinIndex);
      },
      500,
      val,requestinIndex
    );
  };

  const loadMore = useCallback((opts = null) => {
    let requestinIndex = opts?.requestinIndex;

    let currentPage = allowedInfiniteScrollLoadPageRef.current;
    setIsLoadingMore(true);
    salesOrdersController()
      .getSalesOrders({
        page: currentPage,
        size: sizePage,
        openOnly: true,
        orderBy:'so.dateCreated',
        orderDir:'ASC',
        ...salesOrdersSearchFilters.current,
      })
      .then((resp) => {
        setIsLoadingMore(false);
        syncSettings.check(resp);
        if(requestinIndex){
          if(requestinIndex !== loadMoreRequestingIndex.current){
            console.log("Aborted");
            return;
          }
        }
        let posData = resp.data.response.data.return;
        let posTotalSize = resp.data.response.data.totalSize;
        let posExtraData = resp.data.response.data.extra;

        if (currentPage === 0) {
          //first load only
          //loads the extraData that loads in the backend only when the page = 0
          //this is done to avoid double requests just to gather extra unrelated data
        }

        let newArrayLoadedObjs = [];

        if (loadedObjs !== null && !isRestartingSearch.current) {
          newArrayLoadedObjs = [...loadedObjs, ...posData];
        } else {
          newArrayLoadedObjs = posData;
        }
        
        if(isRestartingSearch.current){
          isRestartingSearch.current = false;
        }
        //loadedObjsRef.current = newArrayLoadedObjs;
        setLoadedObjs(newArrayLoadedObjs);

  
        /*let hasMoreElements =
          newArrayLoadedObjs.length < posTotalSize && posData.length !== 0;
        */
       
        let hasMoreElements = posData.length >= sizePage;
        if (hasMoreElements) {
          allowedInfiniteScrollLoadPageRef.current =
            allowedInfiniteScrollLoadPageRef.current + 1;
          infiniteScrollEnabledRef.current = true;
        } else {
          infiniteScrollEnabledRef.current = false;
        }
        if (currentPage === 0) {
          setLoadingPage(false);
        }

      })
      .catch((resp) => {
        setIsLoadingMore(false);
        console.log("THE ERROR: ", resp);
        if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
          if (failedToLoadPosError) {
            setFailedToLoadPosError(false);
          }
          setRequiresNetworkLoadError(true);
        } else {
          if (requiresNetworkLoadError) {
            setRequiresNetworkLoadError(false);
          }
          setFailedToLoadPosError(true);
        }
        console.log("FAILED::", resp);

        setLoadingPage(false);
      });
  }, [
    syncSettings,
    failedToLoadPosError,
    indexReRender,
    requiresNetworkLoadError,
  ]);

  useEffect(() => {
    if (!didMount) {
      loadMore();
      setDidMount(true);
    }
  }, [
    didMount,
    failedToLoadPosError,
    requiresNetworkLoadError,
    sessionState,
    loadMore,
  ]);

  const restartSearch = useCallback((requestinIndex) => {
    allowedInfiniteScrollLoadPageRef.current = 0;
    //loadedObjsRef.current = null;
    isRestartingSearch.current = true;
    loadMore({requestinIndex:requestinIndex});
  }, [loadMore, loadedObjsRef, allowedInfiniteScrollLoadPageRef]);



  useEffect(() => {
    if (previousDidMount === didMount && didMount) {
      if (previousFiltersApplied !== filtersApplied) {
        console.log("filters were changed");
        restartSearch();
      }
    }
  }, [
    filtersApplied,
    previousDidMount,
    didMount,
    previousFiltersApplied,
    restartSearch,
  ]);

  if (!didMount) {
    return <></>;
  }

  return (
    <InAppTemplate>
      <CustomArrowBack pushUrl="/feature/shippings" />
      <div className={styles.pageTitle}>{t("salesorders")}</div>

      <div className={styles.settingsContainer}>
        <div className={styles.customSearchBarContainer}>
          <CustomSearchBar
            value={searchbarInput}
            onResetTextClick={() => {
              initiateFilterCode("");
            }}
            onChange={(evt) => {
              initiateFilterCode(evt.target.value);
            }}
            iconElement={
              <div className={styles.searchIconStyles}>
                <IonIcon icon={searchCircleOutline} />
              </div>
            }
            placeholder={t("salesorder.code")}
          />
        </div>
      </div>
      <div className={styles.salesOrdersContainer}>
        <div
          id="salesOrdersSelectionDiv"
          key={"rerender_ref_selection_" + indexReRender}
          className={styles.salesOrdersSelection}
          ref={posContainerRef}
        >
          {!failedToLoadPosError && loadedObjs !== null ? (
            <>
              {loadedObjs.map((obj, index) => (
                <IonCardHeader
                  key={index}
                  className={styles.elemElement}
                  onClick={() => {
                    props.history.push({
                      pathname: "/feature/shippings/shipping-orders",
                      state: {
                        applyFilters: [
                          {
                            filterKey: "Id_SalesOrder",
                            Id_SalesOrder: obj.Id,
                            orderCode: obj.code,
                            orderName: obj.name,
                          },
                        ],
                      },
                    });
                  }}
                >
                  <div className={styles.elemBackgroundContainer}>
                    <SellIcon />
                  </div>
                  <div className={styles.orderNameCodeContainer}>
                    <div>
                      <b>{t("order")}:</b>
                    </div>
                    <div className={styles.orderNameCode}>
                      <div
                        className={`${styles.singleLineOverFlowElipsis} ${styles.orderLabel}`}
                      >
                        ({obj.code})
                      </div>
                      <div
                        className={`${styles.singleLineOverFlowElipsis} ${styles.orderLabel}`}
                      >
                        {obj.name}
                      </div>
                    </div>
                  </div>
                  <div className={styles.creationDateContainer}>
                    <div>
                      <b>{t("date.created")}:</b>
                    </div>
                    <div>
                      {DatesConversionService().formattedDate(obj.dateCreated)}
                    </div>
                  </div>
                  <div className={styles.progressBarContainer}>
                    <ProgressBar pgValue={obj.salesOrder_completePct} />
                  </div>
                </IonCardHeader>
              ))}{" "}
            </>
          ) : (
            <></>
          )}

          {isLoadingMore && (
            <IonCardHeader className={styles.elemElement} onClick={() => {}}>
              <div className={styles.elemBackgroundContainer}>
                <SellIcon />
              </div>
              <IonCardSubtitle>
                <div className={styles.elemElementTitle}>Loading...</div>
              </IonCardSubtitle>
              <IonCardTitle className={styles.elemElementDescription}>
                <IonSpinner />
              </IonCardTitle>
            </IonCardHeader>
          )}

          <HxfInfiniteScroll
            containerElementRef={posContainerRef}
            requiresContainerHavingScrollbar={true}
            onLoadMore={() => {
              /*let allowingVal = allowedInfiniteScrollLoadPageRef.current + 1;
            
              if(allowingVal > 7){
                return;
              }
              console.log("allowing more :", allowingVal);
               allowedInfiniteScrollLoadPageRef.current = allowedInfiniteScrollLoadPageRef.current + 1;
              */

              if (infiniteScrollEnabledRef.current) {
                
                loadMore();
              }
            }}
            allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
          />
        </div>
      </div>
      
      {failedToLoadPosError ? (
        <div className={styles.errorArea}>
          <CustomUnavailableErrorNotice />
        </div>
      ) : (
        <></>
      )}

      {requiresNetworkLoadError ? (
        <div className={styles.errorArea}>
          <ErrorNoticeRequiresNetworkLoadObject />
        </div>
      ) : (
        <></>
      )}
    </InAppTemplate>
  );
}

export default ShippingsSalesOrdersSelection;
