import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { searchCircleOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import shippingOrdersController from "../../../../../../barrel/controllers/shippingOrdersController";
import usePrevious from "../../../../../../barrel/hooks/usePrevious";
import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomAppliedFilterChip from "../../../../../CustomElements/CustomAppliedFilterChip/CustomAppliedFilterChip";
import CustomArrowBack from "../../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomErrorNotice from "../../../../../CustomElements/CustomErrorNotice/CustomErrorNotice";
import CustomSearchBar from "../../../../../CustomElements/CustomSearchBar/CustomSearchBar";
import ErrorNoticeRequiresNetworkLoadObject from "../../../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import HxfInfiniteScroll from "../../../../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
import ProgressBar from "../../../../../CustomElements/ProgressBar/ProgressBar";
import InAppTemplate from "../../../../../InAppTemplate/InAppTemplate";
import styles from "./ShippingOrdersShow.module.scss";
import DatesConversionService from "../../../../../../barrel/services/datesConversionService";
import CustomUnavailableErrorNotice from "../../../../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
import useSyncSettings from "../../../../../../barrel/hooks/useSyncSettings";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";

function ShippingOrdersShow(props) {
  const {t} = useHxfTranslation();
  const syncSettings = useSyncSettings();
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);
  const previousDidMount = usePrevious(didMount);
  const sizePage = 25;
  const posContainerRef = useRef(null);
  const loadedObjsRef = useRef(null);
  const isRestartingSearch = useRef(false);

  const [loadedObjs, setLoadedObjs] = useState(null);

  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const loadMoreRequestingIndex = useRef(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const infiniteScrollEnabledRef = useRef(false);
  const allowedInfiniteScrollLoadPageRef = useRef(0);
  const [indexReRender, setIndexReRender] = useState(0);

  const [searchbarInput, setSearchbarInput] = useState("");
  const timerStartSearching = useRef(0);

  const shippingOrdersSearchFilters = useRef({});

  const [filtersApplied, setFiltersApplied] = useState([]);
  const filtersAppliedRef = useRef({});
  const previousFiltersApplied = usePrevious(filtersApplied);

  const [applySalesOrderFilterOnRetreat, setApplySalesOrderFilterOnRetreat] = useState(false);
  function isHidden(el) {
    return el.offsetParent === null;
  }

  const initiateFilterCode = (val) => {
    setSearchbarInput(val);
    clearTimeout(timerStartSearching.current); //stops spam requests
    loadMoreRequestingIndex.current = loadMoreRequestingIndex.current + 1;
    let requestinIndex = loadMoreRequestingIndex.current;
    timerStartSearching.current = setTimeout(
      () => {
        shippingOrdersSearchFilters.current = {
          ...shippingOrdersSearchFilters.current,
          searchBarFilter: val,
        };
        restartSearch(requestinIndex);
      },
      500,
      val,
      requestinIndex
    );
  };

  const onFilterChipRemove = (index) => {
    let newFiltersApplied = [];
    for (let i = 0; i < filtersApplied.length; i++) {
      if (i !== index) {
        newFiltersApplied.push(filtersApplied[i]);
      }
    }
    setFiltersApplied(newFiltersApplied); //will trigger useeffect
    filtersAppliedRef.current = newFiltersApplied;
  };

  const getProperFilterChip = (obj, index) => {
    //custom styling of chip
    if (obj.filterKey === "Id_SalesOrder") {
      return (
        <CustomAppliedFilterChip
          filterTextElement={
            <>
              <b>{t("salesorder")}:</b> {obj.orderCode}
            </>
          }
          onRemoveFilter={() => {
            onFilterChipRemove(index);
          }}
        />
      );
    }

    let labelDefault = obj?.filterLabel ? obj.filterLabel : obj.filterKey;
    let chipVal = obj[obj.filterKey];
    //default
    return (
      <CustomAppliedFilterChip
        filterTextElement={
          <>
            <b>{labelDefault}:</b> {chipVal}
          </>
        }
        onRemoveFilter={() => {
          onFilterChipRemove(index);
        }}
      />
    );
  };
  const getFormattedFiltersForRequest = (filtersArray) => {
    let filtersArr = [...filtersArray];
    let formattedFilters = {};
    for (let i = 0; i < filtersArr.length; i++) {
      let filterKey = filtersArr[i].filterKey;
      let filterValue = filtersArr[i][filterKey];
      formattedFilters[filterKey] = filterValue;
    }

    return formattedFilters;
  };

  const getSalesOrderElement = (obj, index) => {
    let salesOrderData = obj.salesOrderData;
    salesOrderData = JSON.parse(salesOrderData);

    if (!salesOrderData?.Id_SalesOrder) {
      //if has no associated order
      return <></>;
    }
    return (
      <div className={styles.orderNameCodeContainer}>
        <div className={styles.orderTopLabel}>
          <b>{t("salesorder")}</b>
        </div>
        <div className={styles.orderNameCode}>
          <div
            className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
          >
            ({salesOrderData.code})
          </div>
          <div
            className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
          >
            {salesOrderData.name}
          </div>
        </div>
      </div>
    );
  };

  const loadMore = useCallback(
    (opts = null) => {

      let passedFilters = opts?.passedFilters ? opts.passedFilters : null;
      let requestinIndex = opts?.requestinIndex;

      let currentPage = allowedInfiniteScrollLoadPageRef.current;

      let extraFiltersApply = {};
     

      if (passedFilters) {
        extraFiltersApply = {...extraFiltersApply, ...getFormattedFiltersForRequest(passedFilters)};
      } 
      if(filtersAppliedRef.current && filtersAppliedRef.current.length > 0){
     
        for(let i = 0; i<filtersAppliedRef.current.length; i++){
          let filterChip = filtersAppliedRef.current[i];
          extraFiltersApply =  {...extraFiltersApply, ...filterChip};
        }
       
      }
     
      
      setIsLoadingMore(true);
      shippingOrdersController()
        .getShippingOrders({
          page: currentPage,
          size: sizePage,
          availableOnly: true,
          includeSalesOrderData: true,
          ...shippingOrdersSearchFilters.current,
          ...extraFiltersApply,
          specialOrderByDueDateAsc:true,
          //orderBy:"so.dueDate",
          //orderDir:"ASC"
        })
        .then((resp) => {
          setIsLoadingMore(false);
          syncSettings.check(resp);

          if(requestinIndex){
            if(requestinIndex !== loadMoreRequestingIndex.current){
              console.log("Aborted");
              return;
            }
          }
          
          let posData = resp.data.response.data.return;
          let posTotalSize = resp.data.response.data.totalSize;
          let posExtraData = resp.data.response.data.extra;

          if (currentPage === 0) {
            //first load only
            //loads the extraData that loads in the backend only when the page = 0
            //this is done to avoid double requests just to gather extra unrelated data
          }

          let newArrayLoadedObjs = [];

          if (loadedObjs !== null && !isRestartingSearch.current) {
            newArrayLoadedObjs = [...loadedObjs, ...posData];
          } else {
            newArrayLoadedObjs = posData;
          }
         

          if(isRestartingSearch.current){
            isRestartingSearch.current = false;
          }
          //loadedObjsRef.current = newArrayLoadedObjs;
          setLoadedObjs(newArrayLoadedObjs);
          /*let hasMoreElements =
            newArrayLoadedObjs.length < posTotalSize && posData.length !== 0;*/
          
          let hasMoreElements = posData.length >= sizePage;
          if (hasMoreElements) {
            allowedInfiniteScrollLoadPageRef.current =
              allowedInfiniteScrollLoadPageRef.current + 1;
            infiniteScrollEnabledRef.current = true;
          } else {
            infiniteScrollEnabledRef.current = false;
          }
          if (currentPage === 0) {
            setLoadingPage(false);
          }
          //setLoadedObjs(loadedObjsRef.current);
          
          //setIndexReRender(indexReRender + 1);
        })
        .catch((resp) => {
          setIsLoadingMore(false);
          console.log("THE ERROR: ", resp);
          if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
            if (failedToLoadPosError) {
              setFailedToLoadPosError(false);
            }
            setRequiresNetworkLoadError(true);
          } else {
            if (requiresNetworkLoadError) {
              setRequiresNetworkLoadError(false);
            }
            setFailedToLoadPosError(true);
          }
          console.log("FAILED::", resp);

          setLoadingPage(false);
        });
    },
    [
      syncSettings,
      failedToLoadPosError,
      indexReRender,
      requiresNetworkLoadError,
      filtersApplied,
    ]
  );
  const restartSearch = useCallback((requestinIndex) => {
    allowedInfiniteScrollLoadPageRef.current = 0;
    loadedObjsRef.current = null;
    isRestartingSearch.current = true;
    loadMore({requestinIndex:requestinIndex});
  }, [loadMore, loadedObjsRef, allowedInfiniteScrollLoadPageRef]);
  useEffect(() => {
    if (!didMount) {
      let loadingFilters = props?.location?.state?.applyFilters;
      if(loadingFilters){
        for(let i = 0; i<loadingFilters.length; i++){
          if(loadingFilters[i]?.Id_SalesOrder){
            setApplySalesOrderFilterOnRetreat(true);
            break;
          }
        }
      }
      if (loadingFilters) {
        
        setFiltersApplied(loadingFilters);
        filtersAppliedRef.current = loadingFilters;
      } else {
        loadingFilters = null;
      }

      window.history.replaceState({}, document.title); //clear history state

      loadMore({opts:loadingFilters});
      setDidMount(true);
    }
  }, [
    props?.location?.state?.applyFilters,
    didMount,
    failedToLoadPosError,
    requiresNetworkLoadError,
    sessionState,
    loadMore,
  ]);

  useEffect(() => {
    if (previousDidMount === didMount && didMount) {
      if (previousFiltersApplied !== filtersApplied) {
        console.log("filters were changed");
        restartSearch();
      }
    }
  }, [
    filtersApplied,
    previousDidMount,
    didMount,
    previousFiltersApplied,
    restartSearch,
  ]);

  if (!didMount) {
    return <></>;
  }

  return (
    <InAppTemplate>
      <CustomArrowBack pushUrl="/feature/shippings" />
      <div className={styles.pageTitle}>{t("shippingorders")}</div>

      <div className={styles.settingsContainer}>
        <div className={styles.customSearchBarContainer}>

          <CustomSearchBar
            value={searchbarInput}
            dataInputFieldIdentifier={"shipping-orders-search"}
            onResetTextClick={() => {
              initiateFilterCode("");
            }}
            onChange={(evt) => {
              initiateFilterCode(evt.target.value);
            }}
            iconElement={
              <div className={styles.searchIconStyles}>
                <IonIcon icon={searchCircleOutline} />
              </div>
            }
            placeholder={t('shippingorder')}
          />
        </div>
      </div>
      {filtersApplied.length > 0 && (
        <div className={styles.appliedFiltersContainer}>
          {filtersApplied.map((obj, index) => (
            <div key={"cafc_" + index}>{getProperFilterChip(obj, index)}</div>
          ))}
        </div>
      )}

      <div className={styles.shippingOrdersContainer}>
        <div
          id="shippingOrdersSelectionDiv"
          key={"rerender_ref_selection_" + indexReRender}
          className={styles.shippingOrdersSelection}
          ref={posContainerRef}
        >
          {!failedToLoadPosError && loadedObjs !== null ? (
            <>
              {loadedObjs.map((obj, index) => (
                <div
                  key={"sho_" + index}
                  className={styles.shippingOrderElement}
                >
                  <IonCardHeader
                    className={styles.elemElement}
                    onClick={() => {

                      let objHistoryPush =  {
                        pathname:"/feature/shippings/shipping-orders/" + obj.Id
                      };

                      if(applySalesOrderFilterOnRetreat){
                        objHistoryPush.state = {
                          applySalesOrderFilterOnRetreat:true
                        };
                      }
                      debugger;
                      props.history.push(
                        objHistoryPush
                      );

   
                    }}
                    data-btn-shippingorder-code={obj.code}
                  >
                    <div className={styles.elemBackgroundContainer}>
                      <LocalShippingIcon />
                    </div>
                    <div className={styles.orderNameCodeContainer}>
                      <div className={styles.orderTopLabel}>
                        <b>{t("shippingorder")}</b>
                      </div>
                      <div className={styles.orderNameCode}>
                        <div
                          className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
                        >
                          ({obj.code})
                        </div>
                        <div
                          className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
                        >
                          {obj.name}
                        </div>
                      </div>
                    </div>
                    {getSalesOrderElement(obj, index)}
                    <div className={styles.creationDateContainer}>
                      <div>
                        <b>{t("date.due")}</b>
                      </div>
                      <div>
                        {obj?.dueDate
                          ? DatesConversionService().tableFormattedDateTimeLabel(obj.dueDate)
                          : t("date.any")}
                      </div>
                    </div>
                    <div className={styles.progressBarContainer}>
                      <ProgressBar pgValue={obj.shippingOrder_completePct} />
                    </div>
                  </IonCardHeader>
                </div>
              ))}{" "}
            </>
          ) : (
            <></>
          )}

          {isLoadingMore && (
            <IonCardHeader className={styles.elemElement} onClick={() => {}}>
              <div className={styles.elemBackgroundContainer}>
                <LocalShippingIcon />
              </div>
              <IonCardSubtitle>
                <div className={styles.elemElementTitle}>Loading...</div>
              </IonCardSubtitle>
              <IonCardTitle className={styles.elemElementDescription}>
                <IonSpinner />
              </IonCardTitle>
            </IonCardHeader>
          )}

          <HxfInfiniteScroll
            containerElementRef={posContainerRef}
            requiresContainerHavingScrollbar={true}
            onLoadMore={() => {
              /*let allowingVal = allowedInfiniteScrollLoadPageRef.current + 1;
            
              if(allowingVal > 7){
                return;
              }
              console.log("allowing more :", allowingVal);
               allowedInfiniteScrollLoadPageRef.current = allowedInfiniteScrollLoadPageRef.current + 1;
              */

              if (infiniteScrollEnabledRef.current) {
               
                loadMore();
              }
            }}
            allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
          />
        </div>
      </div>
      {failedToLoadPosError ? (
        <div className={styles.errorArea}>
          <CustomUnavailableErrorNotice />
        </div>
      ) : (
        <></>
      )}

      {requiresNetworkLoadError ? (
        <div className={styles.errorArea}>
          <ErrorNoticeRequiresNetworkLoadObject />
        </div>
      ) : (
        <></>
      )}
    </InAppTemplate>
  );
}

export default ShippingOrdersShow;
